import axios from 'axios'

export default {

    list(linkedValue) {

        let path = '/api/bo/transfers'
        if (linkedValue !== undefined) {

            path = path + '?isLinked=' + linkedValue
        }

        return axios.get(path);
    },
    get(orderPublicId, resellerId) {

        let path = '/api/bo/transfers/' + orderPublicId

        let data = {

            resellerId: resellerId
        }

        return axios.post(path, data, {headers: {'X-HTTP-Method-Override': 'GET'}});
    }
}
