<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import brandCampaignServices from '../services/brandCampaign'

export default {
    
    components: {DashboardLayout},
    data() {

        return {
            
            brandCampaigns: [],
            selectedStatus: 'running'
        }
    },
    methods: {

        async loadBrandCampaigns() {

            let response = await brandCampaignServices.list(this.selectedStatus)
            this.brandCampaigns = response.data
        }
    },
    created() {

        this.loadBrandCampaigns()
    },
    watch: {

        selectedStatus() {

            this.loadBrandCampaigns()
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Brand Campaigns</h2>
        <div class="form-group">
            <select v-model="selectedStatus">
                <option value="running">Running</option>
                <option value="draft">Draft</option>
                <option value="done">Done</option>
            </select>
        </div>
        <table class="table table-striped">
            <thead>
                <th>ID</th>
                <th>Name</th>
                <th>End date</th>
                <th>Merchant</th>
                <th>Nbr bonus</th>
            </thead>
            <tbody>
                <tr v-for="brandCampaign in brandCampaigns" :key="brandCampaign.id">
                    <td>
                        <router-link :to="{name: 'brandCampaign', params: {brandCampaignId: brandCampaign.id}}">{{brandCampaign.public_id}}</router-link>
                    </td>
                    <td>{{brandCampaign.name}}</td>
                    <td>{{brandCampaign.end_date | date}}</td>
                    <td>{{brandCampaign.merchant.name}}</td>
                    <td>{{brandCampaign.current_nbr_bonus}} / {{ brandCampaign.max_nbr_bonus}}</td>
                </tr>
            </tbody>
        </table>
    </dashboard-layout>
</template>