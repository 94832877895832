<script type="text/ecmascript-6">

import { mapActions } from 'vuex'

export default {

    computed: {

        environment() {

            if (process.env.VUE_APP_IS_STAGING == 'true')
                return 'staging'

            return process.env.NODE_ENV
        }
    },
    methods: {
        ...mapActions({
                signOut: 'logout',
            }),
        async logout() {

            await this.signOut()
            this.$router.go()
        }
    }
}
</script>
<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <a class="navbar-brand" href="#">
            <img width="142px" src="/assets/img/logo-greendid.png" alt="Greendid"/>
        </a>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{$store.getters.user.first_name}} {{$store.getters.user.last_name}}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <router-link class="dropdown-item" :to="{name: 'settings'}">Settings</router-link>
                        <a class="dropdown-item" href="#" @click="logout">Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>
