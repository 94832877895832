import axios from 'axios'

export default {

    attachToReseller(resellerId) {

        let path = '/api/bo/users/role/app/reseller'
        let data = {resellerId: resellerId}

        return axios.patch(path, data);
    },
    getUserPhoneNumber(userId) {

        let path = '/api/bo/users/' + userId + '/phone'

        return axios.get(path);
    },
}
