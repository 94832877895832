<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import resellerService from '../services/reseller'
import userService from '../services/user'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            attachedResellerIds: [],
            resellers: [],
            error : ''
        }
    },
    created() {

        this.loadResellers()
        this.attachedResellerIds = this.getAttachedResellerIds()
    },
    methods: {

        async attachToReseller(reseller) {

            try {
                
                await userService.attachToReseller(reseller.id)
            }
            catch (exception) {

                console.log(exception)
                this.attachedResellerIds = this.getAttachedResellerIds()
                this.error = 'Cannot attach to reseller'
            }
        },
        async loadResellers() {

            let response = await resellerService.list()
            this.resellers = response.data
        },
        getAttachedResellerIds() {

            let ids = []
            if (this.$store.getters.user != undefined) {

                let user = this.$store.getters.user
                for (let i = 0; i < user.roles.length; i++) {

                    let role = user.roles[i]
                    if (role.app == 'Reseller' && role.role == 'Admin') {

                        ids.push(role.reseller_id)
                    }
                }
            }

            return ids
        }
    },
}

</script>
<template>
    <dashboard-layout>
        <h2>Settings</h2>
        <div v-if="error != ''" class="alert alert-danger" role="alert">
            {{error}}
        </div>
        <hr>
        <h3>Act as reseller</h3>
        <div class="border" style="overflow-y:scroll;height:300px">
            <div v-for="reseller in resellers" :key="reseller.id">
                <input type="checkbox" :checked="attachedResellerIds.includes(reseller.id)" :value="reseller.id" @click="attachToReseller(reseller)" />
                &nbsp;<label>{{reseller.name}} ({{reseller.id}})</label>
            </div>
        </div>
    </dashboard-layout>
</template>
