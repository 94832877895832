import axios from 'axios'

export default {

    list() {

        let path = '/api/bo/offerTemplates'
        
        return axios.get(path);
    }
}
