import axios from 'axios'

export default {

    list(status) {

        const path = '/api/bo/brandCampaigns?status[]=' + status

        return axios.get(path);
    },
    get(brandCampaignId) {

        const path = '/api/bo/brandCampaigns/' + brandCampaignId

        return axios.get(path);
    },
    importCode(codes, brandCampaignId) {

        const path = '/api/bo/brandCampaigns/' + brandCampaignId + '/codes'

        var data = {

            codes: codes
        } 

        return axios.post(path, data)
    }
}
