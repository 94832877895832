import axios from 'axios'

export default {

    list() {

        let path = '/api/bo/merchants'
        
        return axios.get(path);
    },
    get(merchantId) {

        let path = '/api/bo/merchants/' + merchantId
        
        return axios.get(path);
    }
}
