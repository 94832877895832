import axios from 'axios'

export default {

    getCSRF() {

        var path = '/sanctum/csrf-cookie'

        return axios.get(path);
    },
    login(email, password) {

        var path = '/api/customer/login'
        var data = {
            email: email,
            password: password,
        }

        return axios.post(path, data)
    },
    logout() {

        var path = '/api/logout'

        return axios.get(path)
    },
    getCurrentUser() {

        var path = '/api/user'

        return axios.get(path)
    }
}
