<script type="text/ecmascript-6">

    import DashboardLayout from '../layouts/Dashboard.vue'
    import jobExecutionService from '../services/jobExecution'
    import { GChart } from 'vue-google-charts'
    
    export default {
        
        components: {DashboardLayout, GChart},
        data() {
    
            return {
    
                jobExecutions: [],
                selectedJobNameFilter: {job_name: 'FnacSendCoupon'},
                jobNameFilters: [],
                selectedStatusFilter : {label: 'All'},
                statusFilters : [
                    {label : 'All'},
                    {label : 'pending'},
                    {label : 'ok'},
                    {label : 'ko'}
                ],
                page: 1,
                lastPage: null,
                totalJobExecutions: null,
                durationChartData: [
                    ['date', 'duration']
                ],
                statusChartData: [
                    ['date', 'pending', 'ok', 'ko']
                ],
                isJobExecutionLoaded: false,
            }
        },
        created() {
    
            this.loadJobNames()
            this.loadJobExecutions(true)
        },
        computed: {

            selectedJobExecutions() {

                if (this.selectedJobNameFilter == undefined) return [];

                this.jobExecutions.filter(jobExecution => this.selectedJobNameFilter.job_name.includes(jobExecution.job_name));
                
                if (this.selectedStatusFilter.label === 'All') {

                    return this.jobExecutions
                } 
                else {
                    
                    return this.jobExecutions.filter(jobExecution => this.selectedStatusFilter.label.includes(jobExecution.status));
                }
            },
        },
        methods: {
    
            async loadJobExecutions(resetPage) {
    
                if (resetPage) {

                    this.page = 1;
                }

                let response = await jobExecutionService.listJobExecutions(this.page, this.selectedJobNameFilter.job_name, this.selectedStatusFilter.label);
                this.jobExecutions = response.data.data;
                this.lastPage = response.data.last_page;
                this.totalJobExecutions = response.data.total

                this.durationChartData = [
                    ['date', 'duration (sec)']
                ];
            
                for (let i = (this.selectedJobExecutions.length)-1; i >= 0; i--) {

                    let date = new Date(this.selectedJobExecutions[i].created_at);
                    
                    if (this.selectedJobExecutions[i].duration === null) {

                        this.selectedJobExecutions[i].duration = 0
                    }

                    if (this.selectedJobExecutions[i].status == 'ok')
                        this.durationChartData.push([date, this.selectedJobExecutions[i].duration]);
                }

                let i = (this.selectedJobExecutions.length-1)
                let newDate = ''
                let pending = 0
                let ok = 0
                let ko = 0
                this.statusChartData = [
                    ['date', 'pending', 'ok', 'ko']
                ]

                while (i >= 0) {

                    let date = new Date(this.selectedJobExecutions[i].created_at).getDate() + '/' + (new Date(this.selectedJobExecutions[i].created_at).getMonth()+1) + '/' +  new Date(this.selectedJobExecutions[i].created_at).getFullYear();

                        if (newDate != date) {

                            if (newDate != '')
                                this.statusChartData.push([newDate, pending, ok, ko]);

                            newDate = date
                            pending = 0
                            ok = 0
                            ko = 0
                            if (this.selectedJobExecutions[i].status === 'pending') {

                                pending++;
                            } 
                            else if (this.selectedJobExecutions[i].status === 'ok') {

                                ok++;
                            } 
                            else if (this.selectedJobExecutions[i].status === 'ko') {

                                ko++;
                            }
                        }
                        else {

                            if (this.selectedJobExecutions[i].status === 'pending') {

                                pending++;
                            } 
                            else if (this.selectedJobExecutions[i].status === 'ok') {

                                ok++;
                            } 
                            else if (this.selectedJobExecutions[i].status === 'ko') {

                                ko++;
                            }
                        }

                        if (i === 0) {

                            this.statusChartData.push([newDate, pending, ok, ko]);
                        }
                    i--;
                }

                if (this.durationChartData.length > 1) {

                    this.isJobExecutionLoaded = true;
                } 
                else {

                    this.isJobExecutionLoaded = false;
                }
            },
            async loadJobNames() {

                let response = await jobExecutionService.listJobNames();
                this.jobNameFilters = response.data
            }
        },
    }
    </script>
    <template>
        <dashboard-layout>
            <h2>Job Executions</h2>

            <select v-model="selectedJobNameFilter" @change="loadJobExecutions(true)">
                <option v-for="jobNameFilter in jobNameFilters" :key="jobNameFilter.job_name" :value="jobNameFilter">{{jobNameFilter.job_name}}</option>
            </select>
            <select v-model="selectedStatusFilter" @change="loadJobExecutions(true)">
                <option v-for="statusFilter in statusFilters" :key="statusFilter.label" :value="statusFilter">{{statusFilter.label}}</option>
            </select>
            <p class="my-2">Total job executions : {{ totalJobExecutions }}</p>

            <GChart v-if="isJobExecutionLoaded" type="LineChart" :data="durationChartData" :options="{vAxis: {minValue: 0, format: ''}, legend: { position: 'bottom', alignment: 'center' }}"/>
            <GChart v-if="isJobExecutionLoaded" type="ColumnChart" :data="statusChartData" :options="{vAxis: {minValue: 0, format: ''}, legend: { position: 'bottom', alignment: 'center' }}"/>

            <div class="d-flex my-3 align-items-center">
                <p class="m-0">Page : </p>
                <select v-model="page" @change="loadJobExecutions(false)" style="height:25px">
                    <option :value="n" v-bind:key="n" v-for="n in lastPage">
                        {{ n }}
                    </option>
                </select>
            </div>
            
            <table class="table table-striped">
                <thead>
                    <th>Public Id</th>
                    <th>Job Name</th>
                    <th>Created at</th>
                    <th>Status</th>
                    <th>Duration <small>(seconds)</small></th>
                </thead>
                <tr v-for="jobExecution in selectedJobExecutions" :key="jobExecution.id">
                    <td>{{jobExecution.public_id}}</td>
                    <td>{{jobExecution.job_name}}</td>
                    <td>{{jobExecution.created_at  | date}}</td>
                    <td>{{jobExecution.status}}</td>
                    <td>{{jobExecution.duration}}  <small>({{ (jobExecution.duration/60).toFixed(0) }} minutes)</small> </td>
                </tr>
            </table>
            <div class="d-flex my-3 align-items-center">
                <p class="m-0">Page : </p>
                <select v-model="page" @change="loadJobExecutions(false)" style="height:25px">
                    <option :value="n" v-bind:key="n" v-for="n in lastPage">
                        {{ n }}
                    </option>
                </select>
            </div>
        </dashboard-layout>
    </template>
    