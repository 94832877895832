<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import resellerServices from '../services/reseller.js'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            reseller: null,
        }
    },
    methods: {

        async loadResellerDetails() {

            let response = await resellerServices.getResellerById(this.resellerId)
            this.reseller = response.data
        },
    },
    created() {
        this.loadResellerDetails()
    },
    computed: {

        resellerId() {

            return this.$route.params.resellerId
        }
    }
}
</script>

<template>
    <dashboard-layout>
        <div v-if="reseller != null">
            <h1>Reseller</h1>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>ID</th>
                        <td class="align-middle">{{reseller.id}}</td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td class="align-middle">{{ reseller.name }}</td>
                    </tr>
                    <tr>
                        <th>Is Active</th>
                        <td class="align-middle">
                            <i v-if="reseller.is_active" class="bi bi-check text-success"></i>
                            <i v-if="!reseller.is_active" class="bi bi-x text-danger"></i>
                        </td>
                    </tr>
                    <tr>
                        <th>Factures</th>
                        <td class="align-middle">
                            <router-link :to="{name: 'resellerInvoices', params: {resellerId: reseller.id}}">
                                Show Invoices
                            </router-link>
                        </td>
                    </tr>
                    <tr>
                        <th>Merchants</th>
                        <td>
                            <ul>
                                <li v-for="merchant in reseller.merchants" :key="merchant.id"><router-link :to="{name:'merchantDetails', params: {merchantId: merchant.id}}">{{ merchant.name }}</router-link></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Open categories</th>
                        <td>
                            <ul>
                                <li v-for="(category,index) in reseller.category_inclusions" :key="index">{{ category.category.name }}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>
