<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import jobService from '../services/job'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            jobs: []
        }
    },
    created() {

        this.loadJobs()
    },
    methods: {

        async loadJobs() {

            let response = await jobService.listJobs()
            this.jobs = response.data
        },
    },
}

</script>
<template>
    <dashboard-layout>
        <h2>Jobs</h2>
        <table class="table table-striped">
            <thead>
                <th>Queue</th>
                <th>Attempts</th>
                <th>Created at</th>
                <th>Params</th>
            </thead>
            <tr v-for="job in jobs" :key="job.id">
                <td>{{job.queue}}</td>
                <td>{{job.attempts}}</td>
                <td>{{job.created_at}}</td>
                <td><small>{{job.params}}</small></td>
            </tr>
        </table>
    </dashboard-layout>
</template>
