<script type="text/ecmascript-6">

import DashboardLayout from '../../layouts/Dashboard.vue'
import analyticsServices from '../../services/analytics'
import moment from 'moment'
import { GChart } from 'vue-google-charts'

export default {
    
    components: {DashboardLayout, GChart},
    data() {

        return {

            basketsData: [
                ['date', 'nbr_baskets']
            ],
            isBasketsDataLoaded: false
        }
    },
    created() {

        this.loadBaskets()
    },
    methods: {

        async loadBaskets() {

            let from = moment().subtract(30, "days").format('YYYY-MM-DD')
            let response = await analyticsServices.loadBaskets(from)
            let data = response.data
            for (let i = 0; i < data.length; i++) {

                this.basketsData.push([new Date(data[i].date), data[i].nbr_baskets])
            }
            this.isBasketsDataLoaded = true
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Baskets</h2>
        <GChart v-if="isBasketsDataLoaded" type="LineChart" :data="basketsData" :options="{vAxis: {minValue: 0}, legend: { position: 'bottom', alignment: 'center' }}"/>
    </dashboard-layout>
</template>
