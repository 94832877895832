import store from '../../store/index.js'

export default {

    async ifAuthenticated(from, to, next) {

        if (store.getters.user != null) {

            next()
        }
        else {
            
            try {

                await store.dispatch('me')
            }
            finally {

                if (store.getters.user != null) {

                    next()
                }
                else {

                    next({name: 'login'})
                }
            }
        }
    },
    async ifNotAuthenticated(to, from, next) {
        
        if (store.getters.user == null) {

            try {

                await store.dispatch('me')
            }
            finally {

                if (store.getters.user == null) {

                    next()
                }
                else {

                    next({name: 'home'})
                }
            }
        }
        else {

            next({name: 'home'})
        }
    }
}
