<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import invoiceServices from '../services/invoice'
import payoutServices from '../services/payout'
import StandardModal from '@/components/StandardModal.vue'

export default {
    
    components: {DashboardLayout, StandardModal},
    data() {

        return {

            invoices: [],
            isSyncLoading: false,
            paid: false,
            error: null,
            type: 'fees',
            selectedInvoices: [],
            confirmPayout: false
        }
    },
    created() {

        this.loadInvoices()
    },
    methods: {

        async loadInvoices() {

            let paidValue = this.paid ? 1 : 0
            let response = await invoiceServices.list(null, paidValue, this.type)
            this.invoices = response.data
        },
        async sync() {

            this.isSyncLoading = true
            await invoiceServices.sync()
            this.isSyncLoading = false
        },
        getFrom(invoices) {

            if (invoices.length > 0 && invoices[0].on_behalf_of) {

                return invoices[0].on_behalf_of.name
            }

            return 'Greendid'
        },
        async openPDF(invoiceId, fileName) {

            let reponse = await invoiceServices.getPDF(invoiceId)
            let content = reponse.data
           
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            let byteCharacters = atob(content);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: 'application/pdf;base64' });
            let fileURL = URL.createObjectURL(file);
            
            a.href = fileURL;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(fileURL);
        },
        async makePayout() {

            this.error = null
            try {

                let ids = this.selectedInvoices.map(invoice => invoice.id)
                await payoutServices.create(ids)
                this.confirmPayout = false;
            }
            catch (e) {

                this.error = e.message
            }

            if (this.error == null) {

                this.selectedInvoice = null
                this.$refs.closeButton.click()
                await this.loadInvoices()
            }
        },
        async markPaid(invoiceId) {

            this.error = null
            try {

                await invoiceServices.markPaid(invoiceId)
            }
            catch (e) {

                this.error = e.message
            }

            if (this.error == null) {

                await this.loadInvoices()
            }
        }
    },
    watch: {

        paid() {

            this.loadInvoices();
        },
        type() {

            this.loadInvoices();
        }
    },
    computed: {

        environment() {

            if (process.env.VUE_APP_IS_STAGING == 'true')
                return 'staging'

            return process.env.NODE_ENV
        },
        displayPayout() {

            return this.paid
        },
        displayMarkAsPaid() {

            return this.type === 'coupons_buy'
        },
        totalPayout() {

            if (this.selectedInvoices.length == 0) return 0

            return this.selectedInvoices.reduce((total, invoice) => total + invoice.amount_due + invoice.reservation_debited_amount, 0)
        }
    }
}

</script>
<template>
    <dashboard-layout>
        <h2>Invoices <button v-if="environment != 'production'" class="btn btn-warning ml-2" :disabled="isSyncLoading" @click="sync"><span v-if="isSyncLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Générer les factures</button></h2>
        <div class="custom-control custom-switch ml-2">
            <input type="checkbox" class="custom-control-input" v-model="paid" id="paidSwitch">
            <label v-if="!paid" class="custom-control-label" for="paidSwitch">Not paid</label>
            <label v-if="paid" class="custom-control-label" for="paidSwitch">paid</label>
        </div>
        <div class="form-group">
            <select v-model="type">
                <option value="fees">Fees</option>
                <option value="fees_b2b">Fees B2B</option>
                <option value="coupons">Coupons</option>
                <option value="coupons_buy">Coupons buy</option>
                <option value="cash">Cash</option>
                <option value="cash_b2b">Cash B2B</option>
            </select>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <th></th>
                    <th>ID</th>
                    <th>Number</th>
                    <th>Edited date</th>
                    <th>Due date</th>
                    <th>Type</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Nbr lines</th>
                    <th>Amount due</th>
                    <th>Amount remaining</th>
                    <th>Amount reservation debited</th>
                    <th>Document</th>
                    <th v-if="displayMarkAsPaid"></th>
                    <th v-if="displayPayout">Payout</th>
                </thead>
                <tbody>
                    <tr v-for="invoice in invoices" :key="invoice.id">
                        <td><router-link title="View invoices batch" :to="{name: 'invoicesBatch', params: {batchId: invoice.batch_id}}"><i class="bi bi-eye"></i></router-link></td>
                        <td>{{invoice.id}}</td>
                        <td>{{invoice.number}}</td>
                        <td>{{invoice.finalized_at | date}}</td>
                        <td>{{invoice.due_date | date}}</td>
                        <td>{{invoice.type}}</td>
                        <td>{{getFrom(invoice)}}</td>
                        <td>{{invoice.customer.name}}</td>
                        <td class="text-right">{{invoice.nbr_lines}}</td>
                        <td class="text-right">{{invoice.amount_due | moneyInteger}}</td>
                        <td class="text-right">{{invoice.amount_remaining | moneyInteger}}</td>
                        <td class="text-right">{{invoice.reservation_debited_amount | moneyInteger}}</td>
                        <td>
                            <button class="btn btn-light" @click="openPDF(invoice.id, invoice.number+'.pdf')">
                                <i class="bi bi-download"></i>
                            </button>
                        </td>
                        <th v-if="displayMarkAsPaid && invoice.paid == false"><button @click="markPaid(invoice.id)" class="btn btn-danger">Mark as paid</button></th>
                        <td v-if="displayPayout"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Modal -->
        <standard-modal v-if="confirmPayout" :closeLabel="'Annuler'" :title="'Confirm payout'" @close="confirmPayout = false">
            <template v-slot:body>
                <div v-if="error" class="alert alert-danger" role="alert">
                    {{error}}
                </div>
                Are you sure to make a payout from <strong>{{getFrom(selectedInvoices)}}</strong>'s wallet to their bank account for <strong>{{totalPayout | moneyInteger}}</strong>?
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-danger" @click="makePayout">Make payout</button>
            </template>
        </standard-modal>
    </dashboard-layout>
</template>
