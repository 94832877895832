import axios from 'axios'

export default {

    list(isLinked) {

        let path = '/api/bo/payouts'
        if (isLinked !== undefined) {

            path = path + '?isLinked=' + isLinked
        }

        return axios.get(path);
    },
    create(invoiceIds) {

        let path = '/api/bo/payouts'
        let data = {
            invoiceIds: invoiceIds
        }

        return axios.post(path, data);
    },
    createByCoupons(couponsIds) {

        let path = '/api/bo/coupons/payout'
        let data = {
            couponIds: couponsIds
        }

        return axios.post(path, data);
    }
}
