<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import jobService from '../services/job'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            failedJobs: []
        }
    },
    created() {

        this.loadFailedJobs()
    },
    methods: {

        async loadFailedJobs() {

            let response = await jobService.list()
            this.failedJobs = response.data
        },
        async retry(failedJob) {

            await jobService.retry(failedJob.uuid)
            this.loadFailedJobs()
        },
        getChunkedException(exception) {

            return exception.length > 40 ? exception.substring(0,40) : exception
        },
        getChunkedParams(params) {

            return params.length > 100 ? params.substring(0,100) : params
        }
    },
}

</script>
<template>
    <dashboard-layout>
        <h2>Failed Jobs</h2>
        <table class="table table-striped">
            <thead>
                <th>Uuid</th>
                <th>Connection</th>
                <th>Queue</th>
                <th>Failed at</th>
                <th>Exception</th>
                <th>Params</th>
                <th></th>
            </thead>
            <tr v-for="failedJob in failedJobs" :key="failedJob.id">
                <td>{{failedJob.uuid}}</td>
                <td>{{failedJob.connection}}</td>
                <td>{{failedJob.queue}}</td>
                <td>{{failedJob.failed_at}}</td>
                <td>{{getChunkedException(failedJob.exception)}}</td>
                <td><small>{{getChunkedParams(failedJob.params)}}</small></td>
                <td><button @click="retry(failedJob)" class="btn btn-danger">Retry</button></td>
            </tr>
        </table>
    </dashboard-layout>
</template>
