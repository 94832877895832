<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import resellerServices from '../services/reseller.js'

export default {
    
    components: {DashboardLayout},
    data() {

        return {
            resellers: []
        }
    },
    methods: {

        async loadResellers() {

            let response = await resellerServices.list()
            this.resellers = response.data
        },
        outStandingMissingAmountClass(reseller) {

            return {'text-danger': reseller.outstanding_missing_amount > 0 && (reseller.outstanding_ceil_amount + reseller.outstanding_ceil_amount_greendid - reseller.outstanding_missing_amount) < 100000}
        }
    },
    created() {

        this.loadResellers()
    },
    computed: {

        isEnvProduction() {

            return process.env.NODE_ENV == 'production' && process.env.VUE_APP_IS_STAGING != 'true'
        },
        totalMissingAmount() {

            return this.resellers.reduce(
                (accumulator, reseller) => accumulator + reseller.outstanding_missing_amount,
                0
            )
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>
            Resellers
        </h2>
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Is Active</th>
                    <th>Outstanding ceil</th>
                    <th>Outstanding Greendid ceil</th>
                    <th>Total Missing</th>
                </thead>
                <tbody>
                    <tr v-for="reseller in resellers" :key="reseller.id">
                        <td class="align-middle">
                            {{ reseller.id }}
                        </td>
                        <td class="align-middle">
                            <router-link :to="{name: 'resellerDetails', params: {resellerId: reseller.id}}">
                                {{ reseller.name }}
                            </router-link>
                        </td>
                        <td>
                            <i v-if="reseller.is_active" class="bi bi-check text-success"></i>
                            <i v-if="!reseller.is_active" class="bi bi-x text-danger"></i>
                        </td>
                        <td class="text-right">{{ reseller.outstanding_ceil_amount | moneyInteger }}</td>
                        <td class="text-right">{{ reseller.outstanding_ceil_amount_greendid | moneyInteger }}</td>
                        <td class="text-right" :class="outStandingMissingAmountClass(reseller)">
                            {{ reseller.outstanding_missing_amount | moneyInteger }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{ totalMissingAmount | moneyInteger }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>
