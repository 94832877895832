<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import orderReservationsService from '@/services/orderReservations'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            orderReservationReclaim: null,
        }
    },
    methods: {

        async loadOrderReservationReclaim() {

            let response = await orderReservationsService.getOrderReservationReclaim(this.jobId)
            this.orderReservationReclaim = response.data
        },
        async cancel() {

            await orderReservationsService.updateOrderReservationReclaim(this.jobId, false)
            this.$router.push({name: 'orderReservationReclaims'})
        },
        async validate() {

            await orderReservationsService.updateOrderReservationReclaim(this.jobId, true)
            this.$router.push({name: 'orderReservationReclaims'})
        }
    },
    created() {

        this.loadOrderReservationReclaim()
    },
    computed: {

        jobId() {

            return this.$route.params.jobId
        }
    }
}
</script>

<template>
    <dashboard-layout>
        <h2>Order Reservation Reclaim Waiting</h2>
        <table v-if="orderReservationReclaim" class="table table-bordered">
            <tbody>
                <tr>
                    <th>Job ID</th>
                    <td>{{orderReservationReclaim.jobId}}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{{orderReservationReclaim.type}}</td>
                </tr>
                <tr>
                    <th>Created At</th>
                    <td>{{orderReservationReclaim.created_at * 1000 | date}}</td>
                </tr>
                <tr>
                    <th>Order ID</th>
                    <td>{{orderReservationReclaim.order.public_id}}</td>
                </tr>
                <tr>
                    <th>Order Created At</th>
                    <td>{{orderReservationReclaim.order.created_at | date}}</td>
                </tr>
                <tr>
                    <th>Order User Price</th>
                    <td>{{orderReservationReclaim.order.price}} €</td>
                </tr>
                <tr>
                    <th>User</th>
                    <td>{{orderReservationReclaim.order.user.first_name + ' ' + orderReservationReclaim.order.user.last_name}}</td>
                </tr>
                <tr>
                    <th>Reclaim Amount</th>
                    <td>{{orderReservationReclaim.amount / 100}} €</td>
                </tr>
                <tr>
                    <th>Reason</th>
                    <td>{{orderReservationReclaim.reason}}</td>
                </tr>
            </tbody>
        </table>
        <div style="display:flex;flex-direction:row;justify-content: space-around;">
            <button @click="cancel" class="btn btn-danger">Annuler</button>
            <button @click="validate" class="btn btn-success btn-lg">Valider</button>
        </div>
    </dashboard-layout>
</template>
