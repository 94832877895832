import axios from 'axios'

export default {

    list(withPayout, merchantId) {

        let path = '/api/bo/coupons?'

        if (withPayout !== undefined) {

            path = path + 'withPayout=' + (withPayout ? 1 : 0)
        }

        if (merchantId !== undefined) {

            path = path + '&merchantId=' + merchantId
        }

        return axios.get(path);
    },
    getCouponState(couponId) {

        let path = '/api/bo/coupons/' + couponId;

        return axios.get(path);
    },
    cancelCoupons(couponId) {

        let path = '/api/bo/coupons/' + couponId+ '/update';

        return axios.get(path);
    }
}
