<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import listingServices from '@/services/listing'
import mediaMixin from '@/mixins/media.js'

export default {
    
    components: {DashboardLayout},
    mixins: [mediaMixin],
    data() {

        return {

            listings: [],
            page: 1,
            lastPage: null,
            totalListings: 0,
            selectedFilter: 'to_validate_greendid',
            filters: ['to_validate_greendid', 'to_validate', 'accepted', 'refused'],
            recommendedPriceFilter: null
        }
    },
    methods: {

        async loadListings() {

            let response = await listingServices.list(this.page, this.selectedFilter, this.recommendedPriceFilter)
            this.listings = response.data.data
            this.lastPage = response.data.last_page
            this.totalListings = response.data.total
        },
        /*async accept(orderPublicId, resellerId) {

            await orderServices.accept(orderPublicId, resellerId)
            this.loadOrders()
        },
        async refuse(orderPublicId, resellerId) {

            await orderServices.refuse(orderPublicId, resellerId)
            this.loadOrders()
        },
        getCommissionRatio(order) {
            
            return (order.commission_excl_tax_price / order.total_price * 100).toFixed(2)
        },
        removeUserId() {

            this.userId = null;
            this.selectedFilter = {label: 'User Action', statuses: [8,1,2,6]}
        },
        isTranferSigned(transferSigned) {

            return transferSigned ? 'Yes' : 'No'
        }*/
    },
    created() {
        
        this.loadListings()
    },
    computed: {

    },
    watch: {

    } 
}
</script>
<template>
    <dashboard-layout>
        <h2>Listings</h2>
        <div class="my-1">
            <small> Total listings : {{ this.totalListings }}</small>
        </div>
        <div class="d-flex">
            <select class="mr-3" v-model="selectedFilter" @change="loadListings">
                <option v-for="filter in filters" :key="filter" :value="filter">{{ filter }}</option>
            </select>
            <div class="custom-control custom-switch">
                <input class="custom-control-input" v-model="recommendedPriceFilter" @change="loadListings" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                <label class="custom-control-label" v-if="recommendedPriceFilter == 1" for="flexSwitchCheckDefault">Avec prix recommandé</label>
                <label class="custom-control-label" v-else-if="recommendedPriceFilter == 0" for="flexSwitchCheckDefault">Sans prix recommandé</label>
                <label class="custom-control-label" v-else for="flexSwitchCheckDefault">Prix recommandé (désactivé)</label>
            </div>
        </div>
        <div class="d-flex my-3 align-items-center">
            <p class="m-0">Page : </p>
            <select v-model="page" @change="loadListings" style="height:25px">
                <option :value="n" v-bind:key="n" v-for="n in lastPage">
                    {{ n }}
                </option>
            </select>
            
        </div>
        <table class="table table-striped">
            <thead>
                <th>Public Id</th>
                <th></th>
                <th>Date</th>
                <th>Full Name</th>
                <th>Status</th>
                <th>Reseller</th>
                <th>Category</th>
                <th>Prix reco.</th>
            </thead>
            <tbody>
                <tr v-for="listing in listings" :key="listing.id">
                    <td class="align-middle">
                        <router-link :to="{name: 'listingDetails', params: {'publicId': listing.public_id}}">{{listing.public_id}}</router-link>
                    </td>
                    <td class="align-middle"><img width="30px" :src="getMerchantAsset(listing.merchant.id, listing.merchant.asset_logo_name)" /></td>
                    <td class="align-middle">{{listing.created_at | date}}</td>
                    <td class="align-middle">{{listing.first_name}} {{listing.last_name}}</td>
                    <td class="align-middle">{{listing.status}}</td>
                    <td class="align-middle">{{listing.reseller.name}}</td>
                    <td class="align-middle">{{listing.category.name}}</td>
                    <td class="align-middle"><i v-if="listing.recommended_price" class="bi bi-check text-success" /></td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex ml-auto">
            <p class="m-0">Page : </p>
            <select  v-model="page" @change="loadListings" style="height:25px">
                <option :value="n" v-bind:key="n" v-for="n in lastPage">
                    {{ n }}
                </option>
            </select>
        </div>
    </dashboard-layout>
</template>
