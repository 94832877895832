<script>

export default {
    
    data() {

        return {
            stripe: null,
            account: {
                business_type: 'company',
                tos_shown_and_accepted: true,
                company: {
                    name: '',
                    address: {
                        line1: '',
                        city: '',
                        state: '',
                        postal_code: ''
                    }
                }
            },
            person: {
                first_name: '',
                last_name: '',
                address: {
                    line1: '',
                    city: '',
                    state: '',
                    postal_code: '',
                },
            },
            accountToken: '',
            personToken: ''
        }
    },
    metaInfo() {

        return {
            script: [{
                src: 'https://js.stripe.com/v3/',
                async: true,
                defer: true,
                callback: () => this.initStripe()
            }]
        }
    },
    methods: {

        initStripe() {

            this.stripe = new window.Stripe('pk_test_51Hn5PfIGwSQUNbx41SEu2mGOZ3FwctxAMEDNkF84PhtI1fnoTz43q63H8mdpxBZlQnMCO4LNerfKJBgX3F3hTnnc00djub4FEn')
        },
        async createToken() {

            const accountResult = await this.stripe.createToken('account', this.account)
            const personResult = await this.stripe.createToken('person', this.person)

            if (accountResult.token && personResult.token) {
                
                this.accountToken = accountResult.token.id
                this.personToken = personResult.token.id
            }
        }
    }
}
</script>
<template>
    <form class="my-form" action="#" @submit.prevent="createToken">
        <input name="token-account" id="token-account" v-model="accountToken">
        <input name="token-person" id="token-person" v-model="personToken">
        <label>
            <span>Business Name</span>
            <input class="inp-company-name" v-model="account.company.name">
        </label>
        <fieldset>
            <legend>Business Address</legend>
            <label>
                <span>Street Address Line 1</span>
                <input class="inp-company-street-address1" v-model="account.company.address.line1">
            </label>
            <label>
                <span>City</span>
                <input class="inp-company-city" v-model="account.company.address.city">
            </label>
            <label>
                <span>State</span>
                <input class="inp-company-state" v-model="account.company.address.state">
            </label>
            <label>
                <span>Postal Code</span>
                <input class="inp-company-zip" v-model="account.company.address.postal_code">
            </label>
        </fieldset>
        <label>
            <span>Representative First Name</span>
            <input class="inp-person-first-name" v-model="person.first_name">
        </label>
        <label>
            <span>Representative Last Name</span>
            <input class="inp-person-last-name" v-model="person.last_name">
        </label>
        <fieldset>
            <legend>Representative Address</legend>
            <label>
                <span>Street Address Line 1</span>
                <input class="inp-person-street-address1" v-model="person.address.line1">
            </label>
            <label>
                <span>City</span>
                <input class="inp-person-city" v-model="person.address.city">
            </label>
            <label>
                <span>State</span>
                <input class="inp-person-state" v-model="person.address.state">
            </label>
            <label>
                <span>Postal Code</span>
                <input class="inp-person-zip" v-model="person.address.postal_code">
            </label>
        </fieldset>
        <button type="submit">Submit</button>
    </form>
</template>
