export default {

    methods: {

        getMerchantAsset(merchandId, assetName) {

            return process.env.VUE_APP_ASSETS_URL + merchandId + '/' + assetName
        },
        openFile(content, fileName) {

            let extension = fileName.split('.').pop()
            let a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"

            let byteCharacters = atob(content)
            let byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {

                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            let byteArray = new Uint8Array(byteNumbers)
            let extToMimes = {
                'jpg': 'image/jpeg',
                'jpeg': 'image/jpeg',
                'png': 'image/png',
                'pdf': 'application/pdf'
            }
            let mimeType = 'application/pdf'
            if (Object.prototype.hasOwnProperty.call(extToMimes, extension)) {
                mimeType = extToMimes[extension];
            }
            let file = new Blob([byteArray], { type: mimeType + ';base64' })
            let fileURL = URL.createObjectURL(file)

            a.href = fileURL
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(fileURL)
        },
    }
}
