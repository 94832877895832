<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import payinServices from '../services/payin'
import invoiceServices from '../services/invoice'
import InputMoney from '../components/dashboard/InputMoney.vue'

export default {
    
    components: {DashboardLayout, InputMoney},
    data() {

        return {

            payin: null,
            invoices: [],
            error: ''
        }
    },
    created() {

        this.loadPayin(this.payinId)
    },
    methods: {

        async loadPayin(payinId) {

            let response = await payinServices.get(payinId);
            this.payin = response.data
            if (this.payin.is_attached == true) {

                this.invoices = this.payin.invoices
            }
        },
        async loadInvoices(customerIds) {

            this.error = ''
            this.amounts = []
            let response = await invoiceServices.list(customerIds);
            this.invoices = response.data
            if (this.invoices.length == 0) {

                this.error = 'No invoice to attach'
            }
            for (let i = 0; i < this.invoices.length; i++) {

                this.$set(this.invoices[i], 'payin_amount', 0)
            }
        },
        toggleAmount(invoice) {

            if (invoice.payin_amount == 0) {

                invoice.payin_amount = this.payin.credited_amount > invoice.amount_remaining ? invoice.amount_remaining : this.payin.credited_amount
            }
            else {

                invoice.payin_amount = 0
            }
        },
        async attach() {

            this.error = ''
            if (this.remainingToAttached != 0) {

                this.error = "Can't attach: you must attach the total amount of the payin"
            }
            else {

                let data = [];
                for (let i = 0; i < this.invoices.length; i++) {

                    if (this.invoices[i].payin_amount !== undefined && this.invoices[i].payin_amount > 0)
                        data.push({'invoiceId': this.invoices[i].id, 'amount': this.invoices[i].payin_amount})
                }

                await payinServices.attach(this.payinId, data)

                this.loadPayin(this.payinId);
            }
        },
        async toSubscription() {

            await payinServices.changeToSubscription(this.payinId)

            this.loadPayin(this.payinId);
        }
    },
    computed: {

        payinId() {

            return this.$route.params.id
        },
        remainingToAttached() {

            let remaining = this.payin.credited_amount
            for (let i = 0; i < this.invoices.length; i++) {

                if (this.invoices[i].payin_amount !== undefined) {

                    remaining -= this.invoices[i].payin_amount
                }
            }

            return remaining
        },
        payinOwner() {

            if (this.payin.type === "mangopay") return this.payin.wallet.owner.name
            
            return this.payin.resellers.reduce((resellerNames, reseller) => resellerNames + (resellerNames == '' ? '' : ' | ') + reseller.name, '')
        },
        payinOwnerId() {

            if (this.payin.type === "mangopay") return [this.payin.wallet.owner_id]

            return this.payin.resellers.map((reseller) => reseller.id)
        },
    }
}

</script>
<template>
    <dashboard-layout>
        <h2>Payin<span v-if="payin && payin.is_subscription" class="ml-2">(Subscription)</span></h2>
        <section v-if="payin">   
            <table class="table col-4">
                <tbody>
                    <tr>
                        <td><strong>ID</strong></td>
                        <td>{{payin.id}}</td>
                    </tr>
                    <tr>
                        <td><strong>From</strong></td>
                        <td>{{payinOwner}}</td>
                    </tr>
                    <tr>
                        <td><strong>Amount</strong></td>
                        <td>{{payin.credited_amount | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <td><strong>Date</strong></td>
                        <td>{{payin.executed_at | date}}</td>
                    </tr>
                    <tr>
                        <td><strong>Attached</strong></td>
                        <td>{{payin.is_attached}}</td>
                    </tr>
                </tbody>
            </table>
            <button v-if="!payin.is_attached && this.invoices.length <= 0" @click="loadInvoices(payinOwnerId)" class="btn btn-success mr-2">Attach to invoices</button>
            <strong v-if="!payin.is_attached">Remaining to attached: {{remainingToAttached | moneyInteger}}</strong>
            <button v-if="!payin.is_attached && this.invoices.length > 0" class="btn btn-success ml-2" @click="attach">Attach</button><br>
            <button :disabled="payin && payin.is_subscription == 1" class="btn btn-info mt-3" @click="toSubscription">Mark this payin as a subscription</button>
            
            <div v-if="error != ''" class="alert alert-danger mt-2" role="alert">
                {{error}}
            </div>
            <h3 v-if="this.invoices.length > 0">Invoices</h3>
            <table v-if="invoices.length > 0" class="table mt-2">
                <thead>
                    <th>ID</th>
                    <th>Number</th>
                    <th>Amount due</th>
                    <th>Amount paid</th>
                    <th>Amount remaining</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="invoice in invoices" :key="invoice.id">
                        <td>{{invoice.id}}</td>
                        <td>{{invoice.number}}</td>
                        <td class="text-right">{{invoice.amount_due | moneyInteger}}</td>
                        <td class="text-right">{{invoice.amount_paid | moneyInteger}}</td>
                        <td class="text-right">{{invoice.amount_remaining | moneyInteger}}</td>
                        <td>{{invoice.type}}</td>
                        <td>{{invoice.finalized_at | date}}</td>
                        <td>
                            <input v-if="invoice.amount_remaining > 0" type="checkbox" @click="toggleAmount(invoice)" />
                            <input-money v-if="invoice.amount_remaining > 0" v-model="invoice.payin_amount" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </dashboard-layout>
</template>
