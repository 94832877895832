import axios from 'axios'

export default {

    list() {

        let path = '/api/bo/resellers'
        
        return axios.get(path);
    },

    getResellerById(resellerId) {

        let path = '/api/bo/resellers/' + resellerId

        return axios.get(path);
    },
    listInvoicesByReseller(resellerId, filterYear, filterPaid, filterType) {

        let path = '/api/bo/resellers/' + resellerId + '/invoices?';

        if (filterYear) {

            path = path + '&filterYear=' + filterYear
        }

        if (filterPaid) {

            path = path + '&filterPaid=' + filterPaid
        }

        if (filterType) {

            path = path + '&filterType=' + filterType
        }

        return axios.get(path);
    },
    makeStripeSepaDebitTransfer(resellerId, amount, description) {

        let path = '/api/bo/resellers/' + resellerId + '/stripeSepaDebitTransfer'

        let data = {
            amount: amount,
            description: description
        }

        return axios.post(path, data)
    }
}
