import axios from 'axios'

export default {

    list() {

        const path = '/api/bo/deliveryNotes'

        return axios.get(path);
    },
    forceStatus(publicId, status) {

        const path = '/api/bo/deliveryNotes/' + publicId
        const data = {
            status: status,
        }

        return axios.patch(path, data);
    },
    sync() {

        const path = '/api/bo/deliveryNotes/sync'

        return axios.post(path);
    },
    listLost() {

        const path = '/api/bo/deliveryNotes/lost'

        return axios.get(path);
    },
}
