<script type="text/ecmascript-6">
    import { mapActions } from 'vuex'

    export default {

        data() {

            return {

                error: null,
                email: null,
                password: null
            }
        },
        methods: {
            ...mapActions({
                login: 'login'
            }),
            async submitSignIn() {

                try {
    
                    await this.login({email: this.email, password: this.password})

                    this.$router.push({name: 'home'})
                }
                catch (error) {

                    this.error = 'Impossible de se connecter'
                }
            },
        }
    }
</script>
<template>
    <div class="container">
        <div class="row mt-4">
            <div class="offset-md-4 col-md-4 col text-center">
                <img src="/assets/img/logo-greendid.webp" width="180" />
            </div>
        </div>
        <div class="row mt-5">
            <div class="offset-md-4 col-md-4 col">
                <div v-if="error" class="alert alert-primary" role="alert">
                    {{error}}
                </div>
                <form action="#" @submit.prevent="submitSignIn">
                    <div class="form-group">
                        <input required type="text" v-model="email" placeholder="Email" class="form-control w-100">
                    </div>
                        <div class="form-group">
                        <input required type="password" v-model="password" placeholder="Mot de passe" class="form-control w-100">
                    </div>
                    <div>
                        <button class="btn btn-success w-100" type="submit">Se connecter</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
