<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import payoutServices from '../services/payout'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            linked: false,
            payouts: []
        }
    },
    created() {

        this.loadpayouts()
    },
    methods: {

        async loadpayouts() {

            let linkedValue = this.linked ? 1 : 0
            let response = await payoutServices.list(linkedValue)
            this.payouts = response.data
        }
    },
    watch: {

        linked() {

            this.loadpayouts()
        } 
    }
}

</script>
<template>
    <dashboard-layout>
        <h2>Payouts</h2>
        <div class="custom-control custom-switch ml-2">
            <input type="checkbox" class="custom-control-input" v-model="linked" id="linkedSwitch">
            <label v-if="!linked" class="custom-control-label" for="linkedSwitch">Not linked</label>
            <label v-if="linked" class="custom-control-label" for="linkedSwitch">Linked</label>
        </div>
        <table class="table table-striped">
            <thead>
                <th>ID</th>
                <th>To</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
                <th v-if="linked">Linked invoice</th>
                <th v-if="!linked">Reference</th>
            </thead>
            <tbody>
                <tr v-for="payout in payouts" :key="payout.id">
                    <td>{{payout.id}}</td>
                    <td>{{payout.wallet.owner.name}}</td>
                    <td class="text-right">{{payout.debited_amount | moneyInteger}}</td>
                    <td>{{payout.created_at | date}}</td>
                    <td>{{payout.status}}</td>
                    <td v-if="linked">{{payout.invoice ? payout.invoice.number : ''}}</td>
                    <td v-if="!linked">{{payout.bank_wire_ref}}</td>
                </tr>
            </tbody>
        </table>
    </dashboard-layout>
</template>
