import axios from 'axios'

export default {

    listReclaimsWaiting() {

        let path = '/api/bo/orderReservationsReclaim/waiting'

        return axios.get(path);
    },
    getOrderReservationReclaim(jobId) {

        let path = '/api/bo/orderReservationsReclaim/waiting/' + jobId

        return axios.get(path);
    },
    updateOrderReservationReclaim(jobId, isValidated) {

        let path = '/api/bo/orderReservationsReclaim/waiting/' + jobId
        let data = {
            
            isValidated: isValidated
        }

        return axios.patch(path, data);
    },
    listOrderReservations(page, status, oid, type) {

        let path = '/api/bo/orderReservations?page=' + page 

        if (status != null) {

            path = path + '&status=' + status
        }

        if (oid != undefined) {

            path = path + '&oid=' + oid
        }

        if (type != undefined) {

            path = path + '&type=' + type
        }

        return axios.get(path);
    },
    getOrderDetails(userId, statuses) {

        let statusesQuery = 'status[]=' + statuses.join('&status[]=')
        let path = '/api/bo/orders?' + statusesQuery

        if (userId != undefined) {

            path = path + '&userId=' + userId
        }
        return axios.get(path);
    },
    getOrderReservationById(orderReservationId) {

        let path = '/api/bo/orderReservation/' + orderReservationId

        return axios.get(path);
    },
    cancelPreAuth(mangoDepositId) {
        
        let path = '/api/bo/depositPreAuth/' + mangoDepositId

        return axios.get(path)
    },
    capturePreAuth(amount, mangoDepositId, reclaimReason, orderId, orderReservationId) {
        
        let path = '/api/bo/depositPreAuth/' + mangoDepositId

        let data = {
            
            amount: amount,
            reclaimReason: reclaimReason,
            orderId: orderId,
            orderReservationId: orderReservationId
        }

        return axios.post(path, data)
    },
    async extractAll() {
      
            let path = '/api/bo/orderReservations/extract';
    
            const response = await axios.get(path, {
                responseType: 'blob'
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'order_reservations.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

    }
}