<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import deliveryService from '../services/delivery'
import orderService from '../services/order'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            lostPackages: [],
            loadingLostPackages: null,
            isLoading:false
        }
    },
    methods: {

        async loadLostPackages() {

            this.isLoading = true
            let response = await deliveryService.listLost()
            this.lostPackages = response.data
            this.isLoading = false
        },

        async setAsLost(orderPublicId, userId) {

            this.isLoading = true
            let response = await orderService.setAsLost(orderPublicId, userId)
            console.log(response.data)
            this.isLoading = false
            this.loadLostPackages()
        }
    },
    created() {

        this.loadLostPackages()
    },
    computed: {

        isEnvProduction() {

            return process.env.NODE_ENV == 'production' && process.env.VUE_APP_IS_STAGING != 'true'
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>
            Lost packages <strong>({{ lostPackages.length }})</strong> 
        </h2>
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <th>ID</th>
                    <th>Expedition Date</th>
                    <th>Tracking</th>
                    <th>Order Type</th>
                    <th>Price</th>
                    <th>Has Coupon</th>
                    <th>Status / Action</th>
                </thead>
                <tbody>
                    <tr v-for="delivery in lostPackages" :key="delivery.public_id">
                        <td class="align-middle"><router-link :to="{name:'orderDetails', params:{orderId:delivery.order_public_id}}">{{delivery.order_public_id}}</router-link></td>
                        <td class="align-middle">{{delivery.updated_at}}</td>
                        <td class="align-middle">{{delivery.tracking_number}}</td>
                        <td class="align-middle">{{delivery.order_coupon_type}}</td>
                        <td class="align-middle">{{delivery.order_price}} €</td>
                        <td class="align-middle">{{delivery.order_coupon_id == undefined ? 'No' : 'Yes'}}</td>
                        <td class="align-middle">
                            <button :disabled="isLoading" v-if="delivery.status_id != 21 && delivery.order_merchant_id != 8451006594" class="btn btn-warning" @click="setAsLost(delivery.order_public_id, delivery.user_id)">
                                Set as lost
                                <div v-if="isLoading" class="spinner-border spinner-border-sm" role="status">
                                </div>
                            </button>
                            <span v-else-if="delivery.status_id === 21 && delivery.is_transfer_signed === 0" class="badge badge-info py-2">Waiting for Signature</span>
                            <span v-else-if="delivery.status_id === 21 && delivery.is_transfer_signed === 1" class="badge badge-secondary py-2">LOST</span>
                            <span v-else class="badge badge-secondary py-2">n/a</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>
