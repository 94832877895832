<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import resellerServices from '../services/reseller.js'
import invoiceServices from '../services/invoice.js'
import StandardModal from '@/components/StandardModal.vue'


export default {
    
    components: {DashboardLayout, StandardModal},
    data() {

        return {

            resellerInvoices: [],
            reseller: [],
            isSyncLoading: false,
            paid: false,
            error: null,
            type: 'fees',
            selectedInvoices: [],
            confirmPayout: false,
            filterYear: '',
            filterIsPaid: '',
            filterType : ''
        }
    },
    created() {

        this.loadInvoices()
        this.loadResellerDetails()
    },
    methods: {

        async loadInvoices() {

            let resellerId = this.$route.params.resellerId
            let response = await resellerServices.listInvoicesByReseller(resellerId, this.filterYear, this.filterIsPaid, this.filterType)
            this.resellerInvoices = response.data
        },
        async loadResellerDetails() {

            let resellerId = this.$route.params.resellerId
            let response = await resellerServices.getResellerById(resellerId)
            this.reseller = response.data
        },
        async sync() {

            this.isSyncLoading = true
            await invoiceServices.sync()
            this.isSyncLoading = false
        },
        getFrom(invoices) {

            if (invoices.length > 0 && invoices[0].on_behalf_of) {

                return invoices[0].on_behalf_of.name
            }

            return 'Greendid'
        },
        async openPDF(invoiceId, fileName) {

            let reponse = await invoiceServices.getPDF(invoiceId)
            let content = reponse.data
           
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            let byteCharacters = atob(content);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: 'application/pdf;base64' });
            let fileURL = URL.createObjectURL(file);
            
            a.href = fileURL;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(fileURL);
        },
        async downloadAll() {

            let ms = 0;
            this.resellerInvoices.forEach(invoice => {
                
                setTimeout(() => {
                    this.openPDF(invoice.id, invoice.number+'.pdf')
                }, ms)
                ms = ms + 1000
            })
        }
    },
    computed: {

        environment() {

            if (process.env.VUE_APP_IS_STAGING == 'true')
                return 'staging'

            return process.env.NODE_ENV
        },
        displayPayout() {

            return this.paid
        },
        displayMarkAsPaid() {

            return this.type === 'coupons_buy'
        },
        totalPayout() {

            if (this.selectedInvoices.length == 0) return 0

            return this.selectedInvoices.reduce((total, invoice) => total + invoice.amount_due + invoice.reservation_debited_amount, 0)
        }
    }
}

</script>
<template>
    <dashboard-layout>
        <h2>{{ reseller.name }} Invoices <button v-if="(environment != 'production' && this.resellerInvoices.length > 0)" class="btn btn-warning ml-2" :disabled="isSyncLoading" @click="sync"><span v-if="isSyncLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Générer les factures</button></h2>
        <div class="form-group">
            <select class="m-2" @change="loadInvoices" v-model="filterYear">
                <option value="">All years</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
            </select>
            <select class="m-2" @change="loadInvoices" v-model="filterIsPaid">
                <option value="">Paid and not paid</option>
                <option value="1">Paid</option>
                <option value="0">Not paid</option>
            </select>
            <select class="m-2" @change="loadInvoices" v-model="filterType">
                <option value="">All types</option>
                <option value="coupons">coupons</option>
                <option value="fees">fees</option>
                <option value="fees_b2b">fees B2B</option>
                <option value="coupons_buy">coupons_buy</option>
                <option value="cash">cash</option>
                <option value="cash_b2b">cash B2B</option>
            </select>
            <button class="btn btn-warning ml-5 m-2" @click="downloadAll">Download all invoices</button>
        </div>
        <div v-if="this.resellerInvoices.length > 0" class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <th></th>
                    <th>ID</th>
                    <th>Number</th>
                    <th>Edited date</th>
                    <th>Due date</th>
                    <th>Type</th>
                    <th>Nbr lines</th>
                    <th>Amount due</th>
                    <th>Amount remaining</th>
                    <th>Is Paid</th>
                    <th>Document</th>
                    <th v-if="displayMarkAsPaid"></th>
                    <th v-if="displayPayout">Payout</th>
                </thead>
                <tbody>
                    <tr v-for="invoice in resellerInvoices" :key="invoice.id">
                        <td><router-link title="View invoices batch" :to="{name: 'invoicesBatch', params: {batchId: invoice.batch_id}}"><i class="bi bi-eye"></i></router-link></td>
                        <td>{{invoice.id}}</td>
                        <td>{{invoice.number}}</td>
                        <td>{{invoice.finalized_at | date}}</td>
                        <td>{{invoice.due_date | date}}</td>
                        <td>{{invoice.type}}</td>
                        <td class="text-right">{{invoice.nbr_lines}}</td>
                        <td class="text-right">{{invoice.amount_due | moneyInteger}}</td>
                        <td class="text-right">{{invoice.amount_remaining | moneyInteger}}</td>
                        <td class="text-center">
                            <i v-if="invoice.paid" class="bi bi-check text-success"></i>
                            <i v-if="!invoice.paid" class="bi bi-x text-danger"></i>
                        </td>
                        <td>
                            <button class="btn btn-light" @click="openPDF(invoice.id, invoice.number+'.pdf')">
                                <i class="bi bi-download"></i>
                            </button>
                        </td>
                        <th v-if="displayMarkAsPaid && invoice.paid == false"><button @click="markPaid(invoice.id)" class="btn btn-danger">Mark as paid</button></th>
                        <td v-if="displayPayout"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <h3>Pas de factures</h3>
        </div>
         <!-- Modal -->
        <standard-modal v-if="confirmPayout" :closeLabel="'Annuler'" :title="'Confirm payout'" @close="confirmPayout = false">
            <template v-slot:body>
                <div v-if="error" class="alert alert-danger" role="alert">
                    {{error}}
                </div>
                Are you sure to make a payout from <strong>{{getFrom(selectedInvoices)}}</strong>'s wallet to their bank account for <strong>{{totalPayout | moneyInteger}}</strong>?
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-danger" @click="makePayout">Make payout</button>
            </template>
        </standard-modal>
    </dashboard-layout>
</template>
