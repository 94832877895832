<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import transferServices from '../services/transfer'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            linked: false,
            transfers: []
        }
    },
    created() {

        this.loadTransfers()
    },
    methods: {

        async loadTransfers() {

            let linkedValue = this.linked ? 1 : 0
            let response = await transferServices.list(linkedValue)
            this.transfers = response.data
        }
    },
    watch: {

        linked() {

            this.loadTransfers()
        } 
    }
}

</script>
<template>
    <dashboard-layout>
        <h2>Transfers</h2>
        <div class="custom-control custom-switch ml-2">
            <input type="checkbox" class="custom-control-input" v-model="linked" id="linkedSwitch">
            <label v-if="!linked" class="custom-control-label" for="linkedSwitch">Not linked</label>
            <label v-if="linked" class="custom-control-label" for="linkedSwitch">linked</label>
        </div>
        <table class="table table-striped">
            <thead>
                <th>ID</th>
                <th>From</th>
                <th>To</th>
                <th>Amount</th>
                <th>Date</th>
            </thead>
            <tbody>
                <tr v-for="transfer in transfers" :key="transfer.id">
                    <td>{{transfer.id}}</td>
                    <td>{{transfer.from_wallet.owner.name}}</td>
                    <td>{{transfer.to_wallet.owner.name}}</td>
                    <td class="text-right">{{transfer.amount | moneyInteger}}</td>
                    <td>{{transfer.execution_date | date}}</td>
                </tr>
            </tbody>
        </table>
    </dashboard-layout>
</template>
