import axios from 'axios'

export default {

    list(page, status, recommendedPrice) {

        let path = '/api/bo/listings?page=' + page

        if (status != undefined) {

            path = path + '&status[]=' + status
        }

        if (recommendedPrice != undefined) {

            path = path + '&rp=' + Number(recommendedPrice)
        }

        return axios.get(path);
    },
    get(listingPublicId) {

        let path = '/api/bo/listings/' + listingPublicId

        return axios.get(path);
    },
    getMerchantInvoice(listingPublicId) {

        let path = '/api/bo/listings/' + listingPublicId + '/merchantInvoice'

        return axios.get(path);
    },
    calculatePrice(listingPublicId, merchantOrderDate, merchantProductPrice) {

        let path = '/api/bo/listings/' + listingPublicId + '/prices' + '?mod=' + merchantOrderDate + '&mpp=' + merchantProductPrice

        return axios.get(path);
    },
    accept(listingPublicId, merchantOrderDate, merchantProductPrice) {

        let path = '/api/bo/listings/' + listingPublicId

        let data = {

            mod: merchantOrderDate,
            mpp: merchantProductPrice,
            action: 'accept'
        }

        return axios.patch(path, data);
    },
    refuse(listingPublicId, reason) {

        let path = '/api/bo/listings/' + listingPublicId

        let data = {

            refusedReason: reason,
            action: 'refuse'
        }

        return axios.patch(path, data);
    },
    getPhotos(listingPublicId) {

        let path = '/api/bo/listings/'+ listingPublicId + '/photos'

        return axios.get(path)
    },
    reopen(listingPublicId) {

        let path = '/api/bo/listings/'+ listingPublicId

        let data = {

            action: 'reopen'
        }

        return axios.patch(path, data);
    },
    reassign(listingPublicId, newResellerId) {

        let path = '/api/bo/listings/'+ listingPublicId

        let data = {

            action: 'reassign',
            newResellerId: newResellerId
        }

        return axios.patch(path, data);
    },
    updateRecommendedPrice(listingPublicId, recommendedPrice) {

        let path = '/api/bo/listings/'+ listingPublicId

        let data = {

            action: 'updateRecommendedPrice',
            recommendedPrice: recommendedPrice * 100
        }

        return axios.patch(path, data);
    },
    updateDetailText(listingPublicId, newDetailText) {

        let path = '/api/bo/listings/'+ listingPublicId

        let data = {

            action: 'updateDetailText',
            newDetailText: newDetailText
        }

        return axios.patch(path, data);
    },
    updateFiles(listingPublicId, newFiles) {

        var formData = new FormData();
        let path = '/api/bo/listings/'+ listingPublicId + '/files'

        let photo1 = newFiles.length >= 1 ? newFiles[0].file : null
        let photo2 = newFiles.length >= 2 ? newFiles[1].file : null
        let photo3 = newFiles.length >= 3 ? newFiles[2].file : null
        let photo4 = newFiles.length === 4 ? newFiles[3].file : null

        formData.append('photo1', photo1)

        if (photo2) {

            formData.append('photo2', photo2)
        }

        if (photo3) {

            formData.append('photo3', photo3)
        }

        if (photo4) {

            formData.append('photo4', photo4)
        }

        return axios.post(path, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
}
