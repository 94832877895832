<script type="text/ecmascript-6">

import DashboardLayout from '../../layouts/Dashboard.vue'
import analyticsServices from '../../services/analytics'
import resellerServices from '../../services/reseller'
import offerTemplateServices from '../../services/offerTemplate'
import { GChart } from 'vue-google-charts'

export default {
    
    components: {DashboardLayout, GChart},
    data() {

        return {

            offerTemplateList: [],
            resellerList: [],
            data: [],

            byOfferTemplateSelect: 0,
            byOfferTemplateResellerSelect: 0,
            isDataByOfferTemplateLoaded: false,
            dataByOfferTemplate: [['Date', 'Max Price']],

            byNameSearchSelect: "",
            byNameSearchResellerSelect: 0,
            isDataByNameSearchLoaded: false,
            dataByNameSearch: [['Date', 'Max Price']],
            nameSearchTitle: "",
        }
    },
    created() {

        this.loadOfferTemplateList()
        this.loadResellerList()
    },
    methods: {

        async loadOfferTemplateList() {

            let response = await offerTemplateServices.list()
            let data = response.data
            for (let i = 0; i < data.length; i++) {

                this.offerTemplateList.push({ value: data[i].id, text: data[i].label})
            }
        },

        async loadResellerList() {

            let response = await resellerServices.list()
            let data = response.data
            for (let i = 0; i < data.length; i++) {

                this.resellerList.push({ value: data[i].id, text: data[i].name})
            }
        },

        async loadByTemplate() {

            let response = await analyticsServices.loadResellerProductOffer(this.byOfferTemplateSelect, '', this.byOfferTemplateResellerSelect)
            if (response !== null) {

                let data = response.data
                this.dataByOfferTemplate = [['Date', 'Max Price']]
                for (let i = 0; i < data.length; i++) {

                    this.dataByOfferTemplate.push([new Date(data[i].date), data[i].price])
                }
                if (this.dataByOfferTemplate.length === 1) {

                    this.dataByOfferTemplate.push([new Date(new Date().setHours(0,0,0,0)), 0])
                }
                this.isDataByOfferTemplateLoaded = true
            }
        },

        async loadByNameSearch() {

            let response = await analyticsServices.loadResellerProductOffer(0, this.byNameSearchSelect, this.byNameSearchResellerSelect)
            if (response !== null) {

                let data = response.data
                this.dataByNameSearch = [['Date', 'Max Price']]
                for (let i = 0; i < data.length; i++) {

                    this.dataByNameSearch.push([new Date(data[i].date), data[i].price])
                }
                if (this.dataByNameSearch.length === 1) {

                    this.dataByNameSearch.push([new Date(new Date().setHours(0,0,0,0)), 0])
                }
                this.isDataByNameSearchLoaded = true
                this.nameSearchTitle = 'Result of \'' + this.byNameSearchSelect + '\''
            } else {
                this.isDataByNameSearchLoaded = false
            }
        }
    },
    watch: {

        byOfferTemplateSelect() {

            this.loadByTemplate()
        },

        byOfferTemplateResellerSelect() {

            this.loadByTemplate()
        },

        byNameSearchResellerSelect() {

            if (this.byNameSearchSelect !== '') {
                this.loadByNameSearch()
            } else {
                this.isDataByNameSearchLoaded = false
            }
        }
    }
}
</script>

<template>
    <dashboard-layout>
        <h2>Max Price by Offer Template</h2>
        <select class="form-control col-md-6 mt-3" v-model="byOfferTemplateSelect">
            <option :value="0">---Select a template---</option>
            <option v-for="template in offerTemplateList" v-bind:key="template.value" :value="template.value">{{template.text}}</option>
        </select>
        <select class="form-control col-md-6 mt-3" v-model="byOfferTemplateResellerSelect">
            <option :value="0">All Reseller</option>
            <option v-for="reseller in resellerList" v-bind:key="reseller.value" :value="reseller.value">{{reseller.text}}</option>
        </select>
        <GChart
            v-if="isDataByOfferTemplateLoaded"
            type="SteppedAreaChart"
            :data="dataByOfferTemplate"
            :options="{
                vAxis: { minValue: 0 },
                legend: { position: 'bottom', alignment: 'center' },
                areaOpacity: 0
            }"
        />
        <h2 class="mt-5">Max Price by Name Search</h2>
        <div class="input-group">
            <input type="text" class="form-control col-md-6" placeholder="Search by name..." v-model="byNameSearchSelect">
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" @click="loadByNameSearch()">
                    <i class="bi bi-search mr-1"></i>
                </button>
            </div>
        </div>
        <select class="form-control col-md-6 mt-3" v-model="byNameSearchResellerSelect">
            <option :value="0">All Reseller</option>
            <option v-for="reseller in resellerList" v-bind:key="reseller.value" :value="reseller.value">{{reseller.text}}</option>
        </select>
        <GChart
            v-if="isDataByNameSearchLoaded"
            type="SteppedAreaChart"
            :data="dataByNameSearch"
            :options="{
                title: this.nameSearchTitle,
                vAxis: { minValue: 0 },
                legend: { position: 'bottom', alignment: 'center' },
                areaOpacity: 0
            }"
        />
    </dashboard-layout>
</template>
