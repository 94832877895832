import Vue from 'vue'
import Vuex from 'vuex'
import authService from '../services/auth.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null
    },
    getters: {
        user: state => { return state.user },
    },
    mutations: {
        setUser (state, value) {
            
            if (value != null) {
                let applicationRole = value.roles.find(element => element.app == 'BO')
                if (applicationRole != null)
                    state.user = value
            }
            else {

                state.user = value
            }
        },
    },
    actions: {
        async getCSRF() {

            await authService.getCSRF()
        },
        async login({ dispatch }, credentials) {

            let response = await authService.login(credentials.email, credentials.password)
            if (response.data != '1' && response.data != '0') {

                sessionStorage.setItem('user-token', response.data);
            }

            return dispatch('me')
        },
        async logout ({ dispatch }) {

            sessionStorage.removeItem('user-token');
            await authService.logout()
    
            return dispatch('me')
        },
        me ({ commit }) {
            return authService.getCurrentUser()
                .then((response) => {

                    commit('setUser', response.data)

                    return response.data
                    
                }).catch(() => {

                    commit('setUser', null)

                    return null
            })
        }
    }
})
