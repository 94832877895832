<script type="text/ecmascript-6">

import DashboardLayout from '../../layouts/Dashboard.vue'
import analyticsServices from '../../services/analytics'
import { GChart } from 'vue-google-charts'

export default {
    
    components: {DashboardLayout, GChart},
    data() {

        return {

            gmvData: [
                ['date', 'gmv']
            ],
            comData: [
                ['date', 'com']
            ],
            couponsAmountData: [
                ['date', 'coupons_amount']
            ],
            nbrCouponsData: [
                ['date', 'nbr_coupons']
            ],
            avgBasketData: [
                ['date', 'avg_basket']
            ],
            avgComData: [
                ['date', 'avg_com']
            ],
            logData: [
                ['date', 'log']
            ],
            avgLogData: [
                ['date', 'avg_log']
            ],
            avgCouponsAmountData: [
                ['date', 'avg_coupons_amount']
            ],
            isBusinessDataLoaded: false,
            merchants: [
                {
                    id: '5049681456',
                    name: 'RDC'
                },
                {
                    id: '6211121839',
                    name: 'Fnac'
                },
                {
                    id: '8451006594',
                    name: 'HDO'
                },
                {
                    id: '9884974566',
                    name: 'Carrefour fr'
                },
            ],
            selectedMerchantId: null
        }
    },
    created() {

        this.loadBusiness()
    },
    methods: {

        initData() {

            this.isBusinessDataLoaded = false
            const currentYear = new Date().getFullYear()
            this.gmvData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
            this.comData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
            this.couponsAmountData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
            this.nbrCouponsData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
            this.avgBasketData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
            this.avgComData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
            this.logData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
            this.avgLogData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
            this.avgCouponsAmountData = [
                ['date', (currentYear - 1).toString(), currentYear.toString()]
            ]
        },
        async loadBusiness() {

            this.initData();
            let response = await analyticsServices.loadBusiness(this.selectedMerchantId)
            let data = response.data
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            
            for (let i = 0; i < monthNames.length; i++) {

                let monthName = monthNames[i]
                
                this.gmvData.push(this.getMonthData(monthName, i + 1, 'GMV', data))
                this.comData.push(this.getMonthData(monthName, i + 1, 'com', data))
                this.nbrCouponsData.push(this.getMonthData(monthName, i + 1, 'nbr_coupons', data))
                this.avgBasketData.push(this.getMonthData(monthName, i + 1, 'avg_basket', data))
                this.avgComData.push(this.getMonthData(monthName, i + 1, 'avg_com', data))
                this.logData.push(this.getMonthData(monthName, i + 1, 'log', data))
                this.avgLogData.push(this.getMonthData(monthName, i + 1, 'avg_log', data))
                this.couponsAmountData.push(this.getMonthData(monthName, i + 1, 'coupons_amount', data))
                this.avgCouponsAmountData.push(this.getMonthData(monthName, i + 1, 'avg_coupons_amount', data))
            }
            this.isBusinessDataLoaded = true
        },
        getMonthData(monthName, month, field, data) {

            const currentYear = new Date().getFullYear()

            let previousYearData =  this.getDataByYearMonth(currentYear - 1, month, data)
            let currentYearData =  this.getDataByYearMonth(currentYear, month, data) 
            let previousDataValue = 0
            let currentDataValue = 0

            if (field == 'avg_coupons_amount') {

                previousDataValue = previousYearData == undefined ? 0 : previousYearData['coupons_amount'] /  previousYearData['nbr_coupons'] / 100
                currentDataValue = currentYearData == undefined ? 0 : currentYearData['coupons_amount'] /  currentYearData['nbr_coupons'] / 100
            }
            else if (field == 'nbr_coupons') {

                previousDataValue = previousYearData == undefined ? 0 : previousYearData[field]
                currentDataValue = currentYearData == undefined ? 0 : currentYearData[field]
            }
            else {

                previousDataValue = previousYearData == undefined ? 0 : previousYearData[field] / 100
                currentDataValue = currentYearData == undefined ? 0 : currentYearData[field] / 100
            }

            return [monthName, previousDataValue, currentDataValue]
        },
        getDataByYearMonth(year, month, data) {

            let datas = data.filter(element => element.year == year && element.month == month)
            
            return datas.length > 0 ? datas[0] : null
        }
    },
    watch: {

        selectedMerchantId() {

            this.loadBusiness();
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Business</h2>
        <select v-model='selectedMerchantId'>
            <option :value="null"></option>
            <option v-for="merchant in merchants" :key="merchant.id" :value="merchant.id">{{merchant.name}}</option>
        </select>
        <h3>GMV</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="gmvData" :options="{vAxis: {minValue: 0, format: '#.## \u20AC'}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Coupons amount</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="couponsAmountData" :options="{vAxis: {minValue: 0, format: '#.## \u20AC'}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Nbr Coupons</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="nbrCouponsData" :options="{vAxis: {minValue: 0}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Avg Coupons amount</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="avgCouponsAmountData" :options="{vAxis: {minValue: 0}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Com</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="comData" :options="{vAxis: {minValue: 0, format: '#.## \u20AC'}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Avg Com</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="avgComData" :options="{vAxis: {minValue: 0, format: '#.## \u20AC'}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Avg Basket</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="avgBasketData" :options="{vAxis: {minValue: 0, format: '#.## \u20AC'}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Logistic</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="logData" :options="{vAxis: {minValue: 0, format: '#.## \u20AC'}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Average Logistic</h3>
        <GChart v-if="isBusinessDataLoaded" type="ColumnChart" :data="avgLogData" :options="{vAxis: {minValue: 0, format: '#.## \u20AC'}, legend: { position: 'bottom', alignment: 'center' }}"/>
    </dashboard-layout>
</template>
