import axios from 'axios'

export default {

    list() {

        let path = '/api/bo/failedJobs'

        return axios.get(path)
    },
    retry(uuid) {

        let path = '/api/bo/failedJobs/' + uuid + '/retry'

        return axios.patch(path)
    },
    listJobs() {

        let path = '/api/bo/jobs'

        return axios.get(path)
    }
}
