<script type="text/ecmascript-6">

export default {

    props: ['value'],
    computed: {
        displayValue: {

            get() {

                return +(Math.round(this.value / 100 + "e+2")  + "e-2")
            },
            set(newValue) {

                this.$emit('input', newValue * 100)
            }
        }
    }
}
</script>
<template>
    <input type="text" class="ml-2 text-right" :readonly="value == 0" v-model="displayValue" />
</template>
