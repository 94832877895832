import axios from 'axios'

export default {

    loadBaskets(from) {

        let path = '/api/bo/baskets/analytics?from=' + from 

        return axios.get(path)
    },
    loadResellerProductOffer(templateId, nameSearch, resellerId) {

        if (templateId === 0 && nameSearch === '') {

            return null
        }
        let path = '/api/bo/resellerProductOffers/analytics?' + 'templateId=' + templateId + '&nameSearch=' + nameSearch + '&resellerId=' + resellerId

        return axios.get(path)
    },
    loadBusiness(merchantId) {

        let path = '/api/bo/orders/analytics'
        if (merchantId != null) {

            path = path + '?merchantId=' + merchantId
        }

        return axios.get(path)
    },
    loadBtb() {

        let path = '/api/bo/btb/analytics'

        return axios.get(path)
    }
}
