<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import brandCampaignServices from '../services/brandCampaign'

export default {
    
    components: {DashboardLayout},
    data() {

        return {
            
            brandCampaign: null,
            csvFile: [],
            isLoading: false,
            error: ''
        }
    },
    methods: {

        async loadBrandCampaign() {

            this.csvFile = []
            let brandCampaignId = this.$route.params.brandCampaignId
            let response = await brandCampaignServices.get(brandCampaignId)
            this.brandCampaign = response.data
        },
        handleFileUpload(event) {
            const file = event.target.files[0];

            const reader = new FileReader();

            return new Promise((resolve) => {
                reader.onload = () => {
                    const lines = reader.result.split('\n');
   
                    const codes = lines.map(line => line.split(';')[0].trim());
                    codes.shift();
                    this.csvFile = codes;

                    resolve(this.csvFile);
                };

                reader.readAsText(file);
            });
        },
        async importCodes() {

            this.isLoading = true
            this.error = ''
            let response = null

            try {

                response = await brandCampaignServices.importCode(this.csvFile, this.brandCampaign.id)
            }
            catch (error) {

                this.isLoading = false
                this.error = error.message
            }

            if (response) {

                this.loadBrandCampaign()
            }
            
            this.isLoading = false 
        }
    },
    computed: {

        categories() {

            let categories = ''
            
            if (this.brandCampaign == undefined) return categories
            
            let isFirst = true
            for (let i = 0; i < this.brandCampaign.categories.length; i++) {

                if (isFirst) 
                    categories = this.brandCampaign.categories[i].name
                else
                    categories = categories + ' ,' + this.brandCampaign.categories[i].name

                isFirst = false
            }

            return categories
        },
        color() {

            let color = ''
            
            if (this.brandCampaign == undefined) return color

            return 'color:' + this.brandCampaign.color
        }
    },
    created() {

        this.loadBrandCampaign()
    }
}
</script>
<template>
    <dashboard-layout>
        <div v-if="brandCampaign != undefined">
            <h2>{{brandCampaign.public_id}}</h2>

            <table class="table table-bordered col-md-6 mt-5">
                <tbody>
                    <tr>
                        <th>ID</th>
                        <td class="align-middle">{{brandCampaign.public_id}}</td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td class="align-middle">{{brandCampaign.name}}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td class="align-middle">{{brandCampaign.status}}</td>
                    </tr>
                    <tr>
                        <th>Amount</th>
                        <td class="align-middle text-right">{{brandCampaign.bonus_amount | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Start Date</th>
                        <td class="align-middle">{{brandCampaign.start_date}}</td>
                    </tr>
                    <tr>
                        <th>End Date</th>
                        <td class="align-middle">{{brandCampaign.end_date}}</td>
                    </tr>
                    <tr>
                        <th>Brand</th>
                        <td class="align-middle">{{brandCampaign.brand.name}}</td>
                    </tr>
                    <tr>
                        <th>Categories</th>
                        <td class="align-middle">{{categories}}</td>
                    </tr>
                    <tr>
                        <th>Merchant</th>
                        <td class="align-middle">{{brandCampaign.merchant_id}}</td>
                    </tr>
                    <tr>
                        <th>Color</th>
                        <td class="align-middle" :style="color">{{brandCampaign.color}}</td>
                    </tr>
                    <tr>
                        <th>Nbr Edited Codes</th>
                        <td class="align-middle" :style="color">{{brandCampaign.countCodes}}</td>
                    </tr>
                    <tr>
                        <th>Action</th>
                        <td class="align-middle text-right" :style="color">            
                            <input type="file" class="mb-4" @change="handleFileUpload">
                            <div v-if="error != ''" class="alert alert-danger">{{ error }}</div>
                            <button :disabled="csvFile.length < 1 || isLoading" @click="importCodes" class="btn btn-success ">Import codes 
                                <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>