<script type="text/ecmascript-6">

    import DashboardHeader from '../components/dashboard/Header.vue'
    import DashboardMenu from '../components/dashboard/Menu.vue'

    export default {
        components: {DashboardHeader, DashboardMenu}
    }
</script>
<template>
    <div>
        <dashboard-header />
        <div class="d-flex flex-row">
            <dashboard-menu />
            <main role="main" class="p-2 w-100">
                <slot/>
            </main>
        </div>
    </div>
</template>
