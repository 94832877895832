<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import orderReservationsService from '@/services/orderReservations'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            orderReservationReclaims: []
        }
    },
    methods: {

        async loadOrderReservationReclaims() {

            let response = await orderReservationsService.listReclaimsWaiting()
            this.orderReservationReclaims = response.data
        },
    },
    created() {

        this.loadOrderReservationReclaims()
    },
}
</script>
<template>
    <dashboard-layout>
        <h2>Order Reservation Reclaims</h2>
        <table class="table table-striped">
            <thead>
                <th></th>
                <th>Order ID</th>
                <th>Created at</th>
                <th>Reason</th>
                <th>Type</th>
            </thead>
            <tbody>
                <tr v-for="orderReservationReclaim in orderReservationReclaims" :key="orderReservationReclaim.jobId">
                    <td><router-link :to="{name: 'orderReservationReclaimDetail', params: {'jobId' : orderReservationReclaim.jobId}}"><i class="bi bi-eye"></i></router-link></td>
                    <td>{{orderReservationReclaim.orderPublicId}}</td>
                    <td>{{orderReservationReclaim.created_at * 1000 | date}}</td>
                    <td>{{orderReservationReclaim.reason}}</td>
                    <td>{{orderReservationReclaim.type}}</td>
                </tr>
            </tbody>
        </table>
    </dashboard-layout>
</template>
