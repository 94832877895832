import axios from 'axios'

export default {

    list(customerIds, paid, type) {

        let path = '/api/bo/invoices?'

        if (type !== undefined) {

            path = path + 'type=' + type
        }

        if (customerIds) {

            let customerIdsQuery = '&rid[]=' + customerIds.join('&rid[]=')
            path = path + customerIdsQuery
        }

        if (paid !== undefined) {

            path = path + '&payout=1&paid=' + paid
        }

        return axios.get(path);
    },
    sync() {

        let path = '/api/bo/invoices/sync'

        return axios.post(path);
    },
    getPDF(invoiceId) {

        let path = '/api/bo/invoices/' + invoiceId + '/pdf'

        return axios.get(path);
    },
    listByBatch(batchId) {

        let path = '/api/bo/invoicesBatch/' + batchId

        return axios.get(path);
    },
    makeSepaDebitTransfer(batchId) {

        let path = '/api/bo/invoicesBatch/' + batchId + '/sepaDebitTransfers'

        return axios.post(path);
    },
    markPaid(invoiceId) {

        let path = '/api/bo/invoices/' + invoiceId + '/paid'

        return axios.post(path);
    }
}
