import Vue from 'vue';

Vue.filter('moneyInteger', function(value) {
  
    let money = +(Math.round(value / 100 + "e+2")  + "e-2")
    if (value % 100 == 0) {

        money = money + '.00'
    }
    else if (value % 10 == 0) {

        money = money + '0'
    }

    return new Intl.NumberFormat('fr-FR').format(money) + '\u00A0€'
});
