import axios from 'axios'

export default {

    list(name, page, userId, statuses) {

        let statusesQuery = 'status[]=' + statuses.join('&status[]=')
        let path = '/api/bo/orders?' + statusesQuery + '&name=' + name + '&page=' + page

        if (userId != undefined) {

            path = path + '&userId=' + userId
        }
        return axios.get(path);
    },
    getOrderDetails(orderPublicId, withContract = false) {

        let path = '/api/bo/orders/' + orderPublicId
        if (withContract) {

            path = path + '?wc=1'
        }

        return axios.get(path);
    },
    accept(orderPublicId, resellerId) {

        let path = '/api/bo/orders/' + orderPublicId + '/accept'
        let data = {rid: resellerId}

        return axios.patch(path, data);
    },
    refuse(orderPublicId, resellerId) {

        let path = '/api/bo/orders/' + orderPublicId + '/refuse'
        let data = {rid: resellerId, reason: 'Ceci est une raison de test'}

        return axios.patch(path, data);
    },
    setAsLost(orderPublicId, userId) {

        const path = '/api/bo/orders/' + orderPublicId +'/lost'
        const data = {
            userId: userId,
        }

        return axios.patch(path, data);
    },
    generateDeliveryNote(orderPublicId, userId) {

        const path = '/api/bo/orders/' + orderPublicId + '/deliveryNotes'

        const data = {
            userId: userId,
        }

        return axios.post(path, data)
    },
    getMerchantInvoice(orderPublicId) {

        const path = '/api/bo/orders/' + orderPublicId +'/merchantInvoice'

        return axios.get(path);
    },
    getPhotos(orderPublicId, userId) {

        let path = '/api/bo/orders/'+ orderPublicId + '/photos'

        let data = {
            userId: userId
        }

        return axios.patch(path, data)
    },
    validateOrder(orderPublicId, accept) {

        let path = '/api/bo/orders/'+ orderPublicId + '/validate'

        let data = {
            accept: accept
        }

        return axios.patch(path, data)
    },
    updatePrice(orderId, userPrice, logisticExclTax, comExclTaxTotal, totalPrice, comInclTaxTotal, logisticInclTax) {

        let path = '/api/bo/orders/' + orderId + '/counterOffer'
        
        let data = {

            userPrice: userPrice.toString(),
            logisticExclTax: logisticExclTax.toString(),
            comExclTaxTotal: comExclTaxTotal.toString(),
            totalPrice: totalPrice.toString(),
            comInclTaxTotal : comInclTaxTotal.toString(),
            logisticInclTax : logisticInclTax.toString()
        }

        return axios.patch(path, data);
    },
    getMerchantBrandCampaignCode(orderPublicId) {     

        let path = '/api/bo/orders/' + orderPublicId + '/merchantbrandcampaigncode'

        return axios.get(path);
    },
    sendNewMerchantBrandCampaignCode(orderPublicId) {

        let path = '/api/bo/orders/' + orderPublicId + '/merchantbrandcampaigncode'

        return axios.patch(path);
    }
}
