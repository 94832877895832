<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import merchantServices from '../services/merchant.js'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            merchant: null,
        }
    },
    methods: {

        async loadMerchantDetails() {

            let response = await merchantServices.get(this.merchantId)
            this.merchant = response.data
        },
       
    },
    created() {

        this.loadMerchantDetails()
    },
    computed: {

        merchantId() {

            return this.$route.params.merchantId
        }
    }
}
</script>

<template>
    <dashboard-layout>
        <div v-if="merchant != null">
            <h1>Merchant</h1>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>ID</th>
                        <td class="align-middle">{{merchant.id}}</td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td class="align-middle">{{ merchant.name }}</td>
                    </tr>
                    <tr>
                        <th>Resellers</th>
                        <td>
                            <ul>
                                <li v-for="reseller in merchant.resellers" :key="reseller.id">
                                    <router-link :to="{name:'resellerDetails', params: {resellerId: reseller.id}}">{{ reseller.name }}</router-link>
                                    <span v-if="reseller.can_handle_async"> - handle async</span>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>
