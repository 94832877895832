import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './filters/moneyInteger.js'
import './filters/date.js'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
    created() {

        this.$store.dispatch('getCSRF')
    }
}).$mount('#app')
