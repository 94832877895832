<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import invoiceServices from '../services/invoice'
import StandardModal from '../components/StandardModal.vue'

export default {
    
    components: {DashboardLayout, StandardModal},
    data() {

        return {

            invoices: [],
            isLoading: false,
            error: '',
            message: '',
            confirmMakeSepaDebitTransfer: false,
            canDisplayStripeDebitButton: false,
            canDisplayStripeDebitButtonForFees: false,
            canDisplayStripeDebitButtonB2B: false,
            amountCouponsBuy: 0,
            isStripe: null,
            order:null,
            amountStripeB2B:0
        }
    },
    created() {

        this.loadInvoices()
    },
    methods: {

        async loadInvoices() {

            this.canDisplayStripeDebitButton = false
            this.canDisplayStripeDebitButtonForFees = false
            this.canDisplayMangoDebitButton = false
            this.amountCouponsBuy = 0
            
            let batchId = this.$route.params.batchId
            let response = await invoiceServices.listByBatch(batchId)
            this.invoices = response.data

            let invoicesTypeFees = 0
            let invoicesTypeCoupons = 0
            let invoicesTypeCash = 0

            for (let i = 0; i < this.invoices.length; i++) {

                if (this.invoices[i].type === 'cash_b2b' || this.invoices[i].type === 'fees_b2b') {
                    
                    this.amountStripeB2B += this.invoices[i].amount_due

                    if (this.amountStripeB2B > 0)
                        this.canDisplayStripeDebitButtonB2B = true
                }

                if (this.invoices[i].type === 'coupons_buy' && this.invoices[i].amount_remaining > 0) {
                    
                    this.amountCouponsBuy += this.invoices[i].amount_due
                    this.canDisplayStripeDebitButton = true
                }

                if (this.invoices[i].type === 'coupons') {

                    invoicesTypeCoupons++
                    this.canDisplayMangoDebitButton = true
                }
                else if (this.invoices[i].type === 'fees') {

                    invoicesTypeFees++
                }
                else if (this.invoices[i].type === 'cash') {

                    invoicesTypeCash++
                    this.canDisplayMangoDebitButton = true
                }
            }
            
            if (invoicesTypeCoupons === 0 && invoicesTypeCash === 0 && invoicesTypeFees > 0) {
                
                this.canDisplayStripeDebitButtonForFees = true
            }  
        },
        async openPDF(invoiceId, fileName) {

            let reponse = await invoiceServices.getPDF(invoiceId)
            let content = reponse.data
           
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            let byteCharacters = atob(content);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: 'application/pdf;base64' });
            let fileURL = URL.createObjectURL(file);
            
            a.href = fileURL;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(fileURL);
        },
        getFrom(invoice) {

            if (invoice.on_behalf_of) {

                return invoice.on_behalf_of.name
            }

            return 'Greendid'
        },
        async makeSepaDebitTransfer() {

            let batchId = this.$route.params.batchId
            this.isLoading = true    
            this.confirmMakeSepaDebitTransfer = false

            try { 
                
                let response = await invoiceServices.makeSepaDebitTransfer(batchId)
                if (response.data == '1') 
                    this.message = 'The SEPA Debit transfer will be handled in few minutes'
                else
                    this.error = response.data
            }
            catch (exception) {

                this.error = exception.message
            }
        },
    },
    computed: {

        canMakeSepaDebitTransfer() {

            return this.invoicesTotalAmountForSepaDebitTransfer > 0
        },
        invoicesTotalAmountForSepaDebitTransfer() {

            return this.invoices.reduce((total, invoice) => invoice.type != 'coupons_buy' && invoice.payment_type == 'sepa_debit_transfer' ? total + invoice.amount_remaining : total, 0)
        },
        resellerName() {

            if (this.invoices.length > 0) {

                return this.invoices[0].customer.name
            }

            return ''
        },
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Invoices Batch</h2>
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <th>ID</th>
                    <th>Number</th>
                    <th>Edited date</th>
                    <th>Due date</th>
                    <th>Type</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Nbr lines</th>
                    <th>Amount due</th>
                    <th>Amount remaining</th>
                    <th>Is Paid</th>
                    <th>Document</th>
                </thead>
                <tbody>
                    <tr v-for="invoice in invoices" :key="invoice.id">
                        <td>{{invoice.id}}</td>
                        <td>{{invoice.number}}</td>
                        <td>{{invoice.finalized_at | date}}</td>
                        <td>{{invoice.due_date | date}}</td>
                        <td>{{invoice.type}}</td>
                        <td>{{getFrom(invoice)}}</td>
                        <td>{{invoice.customer.name}}</td>
                        <td class="text-right">{{invoice.nbr_lines}}</td>
                        <td class="text-right">{{invoice.amount_due | moneyInteger}}</td>
                        <td class="text-right">{{invoice.amount_remaining | moneyInteger}}</td>
                        <td class="text-center">
                            <i v-if="invoice.paid" class="bi bi-check text-success"></i>
                            <i v-if="!invoice.paid" class="bi bi-x text-danger"></i>
                        </td>
                        <td>
                            <button class="btn btn-light" @click="openPDF(invoice.id, invoice.number+'.pdf')">
                                <i class="bi bi-download"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="error != ''" class="alert alert-danger" role="alert">
            {{error}}
        </div>
        <div v-if="message != ''" class="alert alert-success" role="alert">
            {{message}}
        </div>
        <div v-if="invoices.length > 0 && canMakeSepaDebitTransfer" class="form-group">
            <button :disabled="isLoading" @click="confirmMakeSepaDebitTransfer = true" class="btn btn-danger text-light">Make SEPA Debit Transfer <br/>
                <strong v-if="canDisplayMangoDebitButton" class="text-white" >
                    MANGO : {{invoicesTotalAmountForSepaDebitTransfer|moneyInteger}}<br/>
                </strong>
                <strong v-if="canDisplayStripeDebitButtonForFees" class="text-white" >
                    STRIPE (fees) : {{invoicesTotalAmountForSepaDebitTransfer|moneyInteger}}<br/>
                </strong>
                <strong v-if="canDisplayStripeDebitButton" class="text-white" >
                    STRIPE : {{amountCouponsBuy|moneyInteger}}
                </strong>
                <strong v-if="canDisplayStripeDebitButtonB2B" class="text-white">
                    STRIPE : {{amountStripeB2B|moneyInteger}}
                </strong>
            </button>
        </div>
        <standard-modal v-if="confirmMakeSepaDebitTransfer" :closeLabel="'Annuler'" :title="'Confirm SEPA Debit Transfer'" @close="confirmMakeSepaDebitTransfer = false">
            <template v-slot:body>
                <p>Are you sure to make a SEPA Debit Transfer>
                    <strong>
                        {{resellerName}}
                    </strong> 
                    for <br/>
                    <span v-if="canDisplayMangoDebitButton" class="text-danger">
                        MANGO : {{invoicesTotalAmountForSepaDebitTransfer|moneyInteger}}
                    </span><br/>
                    <span v-if="canDisplayStripeDebitButtonForFees" class="text-info" >
                        STRIPE (fees) : {{invoicesTotalAmountForSepaDebitTransfer|moneyInteger}}<br/>
                    </span>
                    <span v-if="canDisplayStripeDebitButton" class="text-info">
                        STRIPE : {{amountCouponsBuy|moneyInteger}}
                    </span>
                    <span v-if="canDisplayStripeDebitButtonB2B" class="text-info">
                        STRIPE : {{amountStripeB2B|moneyInteger}}
                    </span>
                </p>
            </template>
            <template v-slot:footer>
                <button :disabled="isLoading" role="button" class="btn btn-danger" @click="makeSepaDebitTransfer(isStripe)">
                    Valider
                </button>
            </template>
        </standard-modal>
    </dashboard-layout>
</template>