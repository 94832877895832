<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import orderServices from '@/services/order'
import mediaMixin from '@/mixins/media.js'

export default {
    
    components: {DashboardLayout},
    mixins: [mediaMixin],
    data() {

        return {

            orders: [],
            selectedFilter: {label: 'Greendid Action', statuses: [11, 6]},
            filters: [
                {label: 'Greendid Action', statuses: [11, 6]},
                {label: 'User Action', statuses: [8,1,2,6]},
                {label: 'Done', statuses: [10]},
                {label: 'To make done', statuses: [5]},
                {label: 'Reseller action', statuses: [3,4,7]},
                {label: 'Archived', statuses: [9,20,21]},
                {label: 'All', statuses: [1,2,3,4,5,6,7,8,9,10,20,21]}
            ],
            name: '',
            page: 1,
            lastPage: null,
            userId: this.$route.params.userId,
            totalOrders: null,
        }
    },
    methods: {

        async loadOrders() {

            let response = await orderServices.list(this.name, this.page, this.userId, this.selectedFilter.statuses)
            this.orders = response.data.data
            this.lastPage = response.data.last_page

            if (this.selectedFilter.label === 'Greendid Action') {

                this.totalOrders = this.orders.filter(element => element.order_status_id === 11 || element.source_type === 'store').length
            }
            else {

                this.totalOrders = response.data.total
            }
        },
        async accept(orderPublicId, resellerId) {

            await orderServices.accept(orderPublicId, resellerId)
            this.loadOrders()
        },
        async refuse(orderPublicId, resellerId) {

            await orderServices.refuse(orderPublicId, resellerId)
            this.loadOrders()
        },
        getCommissionRatio(order) {
            
            return (order.commission_excl_tax_price / order.total_price * 100).toFixed(2)
        },
        removeUserId() {

            this.userId = null;
            this.selectedFilter = {label: 'User Action', statuses: [8,1,2,6]}
        },
        isTranferSigned(transferSigned) {

            return transferSigned ? 'Yes' : 'No'
        }

    },
    mounted() {
        
        if (this.userId != null) {

            this.selectedFilter = {label: 'All', statuses: [1,2,3,4,5,6,7,8,9,10,20,21]}
        }
        else
            this.loadOrders()
    },
    computed: {

        isEnvProduction() {

            return process.env.NODE_ENV == 'production' && process.env.VUE_APP_IS_STAGING != 'true'
        },
        selectedOrders() {

            return this.selectedFilter.label === 'Greendid Action' ? this.orders.filter(element => element.order_status_id === 11 || element.source_type === 'store') : this.orders
        }
    },
    watch: {

        selectedFilter() {

            this.loadOrders();
        }
    } 
}
</script>
<template>
    <dashboard-layout>
        <h2>Orders</h2>

        <h5 v-if="userId != null">
            User {{ this.$route.params.userOrder.user.first_name }} {{ this.$route.params.userOrder.user.last_name }} (user_id : {{ this.$route.params.userOrder.user_id }})
            <button type="button" class="btn-close text-danger border-0 bg-transparent" @click="removeUserId">X</button>
        </h5>
        
        <div style="width:50%; height:25px" class="d-flex flex-row mb-1 p-0">
            <select v-model="selectedFilter">
                <option v-for="filter in filters" :key="filter.label" :value="filter">{{filter.label}}</option>
            </select>
        

            <input :disabled="userId != null" placeholder="Name" class="ml-2 p-0" v-model="name" style="height: 25px;" @click="selectedFilter = {label: 'All', statuses: [1,2,3,4,5,6,7,8,9,10,20,21]}"/>

            <button :disabled="userId != null" style="height: 25px;" class="btn btn-primary m-0 p-0 align-items-start" @click="loadOrders">
                <p class="mx-2">Search</p>
            </button>
            
        </div>
        <p class="my-1">
                <small> Total orders : {{ this.totalOrders }}</small>
            </p>
        <div class="d-flex my-3 align-items-center">
            <p class="m-0">Page : </p>
            <select v-model="page" @change="loadOrders" style="height:25px">
                <option :value="n" v-bind:key="n" v-for="n in lastPage">
                    {{ n }}
                </option>
            </select>
            
        </div>
 
        <table v-if="selectedOrders != undefined" class="table table-striped">
            <thead>
                <th>Public Id</th>
                <th></th>
                <th>Full Name</th>
                <th>Date</th>
                <th>Status</th>
                <th>Type</th>
                <th>Reseller</th>
                <th>Category</th>
                <th>User price</th>
                <th>Log</th>
                <th>Com</th>
                <th>Total price</th>
                <th>Transfer</th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="order in selectedOrders" :key="order.id">
                    <td class="align-middle">
                        <router-link title="Click to open details" :to="{name: 'orderDetails', params: {orderId: order.public_id}}">
                            {{order.public_id}}
                        </router-link>
                    </td>
                    <td class="align-middle"><img width="30px" :src="getMerchantAsset(order.merchant.id, order.merchant.asset_logo_name)" /></td>
                    <td class="align-middle">{{order.user.first_name}} {{order.user.last_name}}</td>
                    <td class="align-middle">{{order.created_at | date}}</td>
                    <td class="align-middle">{{order.status.name}}</td>
                    <td class="align-middle">{{order.coupon_type}}</td>
                    <td class="align-middle">{{order.reseller.name}}</td>
                    <td class="align-middle">{{order.category.name}}</td>
                    <td class="align-middle text-right text-nowrap">{{Number(order.price) + Number(order.contribution_amount)}} €</td>
                    <td class="align-middle text-right text-nowrap">{{order.logistic_excl_tax_price}} €</td>
                    <td class="align-middle text-right text-nowrap">{{order.commission_excl_tax_price}} €</td>
                    <td class="align-middle text-right text-nowrap">{{order.total_price}} €</td>
                    <td :class="{'text-success': order.is_transfer_signed, 'text-danger' :!order.is_transfer_signed}" class="align-middle text-right text-nowrap">{{ isTranferSigned(order.is_transfer_signed) }}</td>
                    <td class="align-middle">
                        <div v-if="!isEnvProduction && order.status.name == 'TO_VALIDATE'">
                            <button class="btn btn-warning" @click="accept(order.public_id, order.reseller_id)">Accept</button>
                            <button class="btn btn-warning ml-2" @click="refuse(order.public_id, order.reseller_id)">Refuse</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex ml-auto">
            <p class="m-0">Page : </p>
            <select  v-model="page" @change="loadOrders" style="height:25px">
                <option :value="n" v-bind:key="n" v-for="n in lastPage">
                    {{ n }}
                </option>
            </select>
        </div>
    </dashboard-layout>
</template>
