<script type="text/ecmascript-6">

import DashboardLayout from '../../layouts/Dashboard.vue'
import analyticsServices from '../../services/analytics'
import { GChart } from 'vue-google-charts'

export default {
    
    components: {DashboardLayout, GChart},
    data() {

        return {

            gmvData: [
                ['date', 'gmv']
            ],
            comData: [
                ['date', 'com']
            ],
            isBtbDataLoaded: false
        }
    },
    created() {

        this.loadBtb()
    },
    methods: {

        async loadBtb() {

            let response = await analyticsServices.loadBtb()
            let data = response.data
            for (let i = 0; i < data.length; i++) {

                this.gmvData.push([new Date(data[i].date), data[i].gmv / 100])
                this.comData.push([new Date(data[i].date), data[i].commission / 100])
            }
            this.isBtbDataLoaded = true
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Btb</h2>
        <h3>GMV</h3>
        <GChart v-if="isBtbDataLoaded" type="ColumnChart" :data="gmvData" :options="{vAxis: {minValue: 0}, legend: { position: 'bottom', alignment: 'center' }}"/>
        <h3>Commission</h3>
        <GChart v-if="isBtbDataLoaded" type="ColumnChart" :data="comData" :options="{vAxis: {minValue: 0}, legend: { position: 'bottom', alignment: 'center' }}"/>
    </dashboard-layout>
</template>
