import axios from 'axios'

export default {

    list() {

        let path = '/api/bo/kyc'

        return axios.get(path)
    },
    getKycFiles(kycId) {

        let path = '/api/bo/kyc/' + kycId + '/files'

        return axios.get(path, {responseType: 'blob'});
    },
    get(kycId) {

        let path = '/api/bo/kyc/' + kycId

        return axios.get(path);
    },
    getKycFile1(kycId) {

        let path = '/api/bo/kyc/' + kycId + '/files/1'

        return axios.get(path);
    },
    getKycFile2(kycId) {

        let path = '/api/bo/kyc/' + kycId + '/files/2'

        return axios.get(path);
    },
    validate(kycId, firstName, lastName, type, number, delivery, country, nationality, residenceCountry, birthday) {

        let path = '/api/bo/kyc/' + kycId
        let data = {
            firstName: firstName,
            lastName: lastName,
            type: type,
            number: number,
            delivery: delivery,
            country: country,
            nationality: nationality,
            residenceCountry: residenceCountry,
            birthday: birthday
        }

        return axios.patch(path, data);
    },
    refuse(kycId) {

        let path = '/api/bo/kyc/' + kycId + '/refuse'
        
        return axios.patch(path);
    },

    createOrGetEmpty(userId) {

        let path ='/api/bo/kyc/' + userId

        return axios.post(path)
    }
}
