<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import resellerServices from '@/services/reseller.js'

export default {
    
    components: {DashboardLayout},
    data() {

        return {
            resellers: [],
            reseller: null,
            description: '',
            amount: 0,
            lastPayins: [],
            error: null,
            isLoading: false
        }
    },
    methods: {

        async loadResellers() {

            let response = await resellerServices.list()
            this.resellers = response.data
        },
        async makePayin() {

            this.error = null

            if (this.amount < 200) {

                try {

                    this.isLoading = true
                    await resellerServices.makeStripeSepaDebitTransfer(this.reseller.id, this.amount * 100, this.description)

                    this.lastPayins.push({
                        reseller: this.reseller,
                        amount: this.amount,
                        description: this.description
                    })
                }
                catch (error) {

                    this.error = error.message
                }
            }
            else {

                this.error = 'Amount to hight'
            }

            this.reseller = null
            this.amount = 0
            this.description = ''
            this.isLoading = false
        }
    },
    created() {

        this.loadResellers()
    },
    computed: {

        isSubmitButtonDisabled() {

            if (this.isLoading) return true

            if (this.amount <= 0 || this.description.length <= 0 || this.reseller == undefined) return true

            return false
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>
            Reseller Stripe Payin
        </h2>
        <div class="row mt-4">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-2">
                            <label class="form-label">Reseller</label>
                            <select v-model="reseller" class="form-control">
                                <option v-for="reseller in resellers" :key="reseller.id" :value="reseller">{{ reseller.name }}</option>
                            </select>
                        </div>
                        <div class="mb-2">
                            <label class="form-label">Description</label>
                            <input type="text" v-model="description" class="form-control" />
                        </div>
                        <div class="mb-2">
                            <label class="form-label">Amount (€)</label>
                            <input type="number" v-model="amount" class="form-control">
                        </div>
                        <button :disabled="isSubmitButtonDisabled" type="submit" @click="makePayin" class="btn btn-primary mt-2">Make Stripe Payin</button>
                        <div v-if="error" class="alert alert-danger mt-2">{{ error }}</div>
                    </div>
                </div>
            </div>
            <div class="col">
                <h3>Last payins</h3>
                <table class="table table-bordered">
                    <thead>
                        <th>Reseller</th>
                        <th>Amount</th>
                        <th>Description</th>
                    </thead>
                    <tbody>
                        <tr v-for="lastPayin in lastPayins" :key="lastPayin.reseller.id">
                            <td>{{ lastPayin.reseller.name }}</td>
                            <td>{{ lastPayin.amount }} €</td>
                            <td>{{ lastPayin.description }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </dashboard-layout>
</template>
