<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import payinServices from '../services/payin'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            paid: false,
            payins: [],
        }
    },
    created() {

        this.loadPayins()
    },
    methods: {

        async loadPayins() {

            let paidValue = this.paid ? 1 : 0
            let response = await payinServices.list(paidValue)
            this.payins = response.data
        },
        stripeCustomerId(payin) {
    
            return payin.stripe_customer_id != null ? payin.resellers.reduce((resellerNames, reseller) => resellerNames + (resellerNames == '' ? '' : ' | ') + reseller.name, '') : ''
        },
    },
    watch: {

        paid() {

            this.loadPayins()
        } 
    },
    computed: {

        filteredPayins() {

            return this.payins.filter(payin => (payin.type === 'mangopay' || (payin.type === 'stripe' && payin.stripe_customer_id != null)) && payin.is_subscription != 1)
        }
    }
}

</script>
<template>
    <dashboard-layout>
        <h2>Payins</h2>
        <div class="custom-control custom-switch ml-2">
            <input type="checkbox" class="custom-control-input" v-model="paid" id="paidSwitch">
            <label v-if="!paid" class="custom-control-label" for="paidSwitch">Not paid</label>
            <label v-if="paid" class="custom-control-label" for="paidSwitch">paid</label>
        </div>
        <table class="table table-striped">
            <thead>
                <th>ID</th>
                <th>From</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Date</th>
                <th>Status</th>
            </thead>
            <tbody>
                <tr v-for="payin in filteredPayins" :key="payin.id">
                    <td>
                        <router-link v-if="payin.status == 'SUCCEEDED'" :to="{name:'payin', params: {id: payin.id}}">{{payin.id}}</router-link>
                        <span v-else>{{payin.id}}</span>
                    </td>
                    <td>{{payin.wallet != undefined ? payin.wallet.owner.name : stripeCustomerId(payin)}}</td>
                    <td class="text-right">{{payin.credited_amount | moneyInteger}}</td>
                    <td>{{payin.type}}</td>
                    <td>{{payin.executed_at | date}}</td>
                    <td>{{payin.status}}</td>
                </tr>
            </tbody>
        </table>
    </dashboard-layout>
</template>
