import axios from 'axios'

export default {

    listJobExecutions(page, jobNameFilter, statusFilter) {

        let path = '/api/bo/jobExecutions?page=' + page + '&jobNameFilter=' + jobNameFilter + '&statusFilter=' + statusFilter

        return axios.get(path)
    },
    listJobNames() {

        let path = '/api/bo/jobExecutions/jobNames'

        return axios.get(path)
    },
}
