<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import orderReservationsService from '@/services/orderReservations'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            orderReservations: [],
            statuses: [
                {label:"ACCEPTED"},
                {label:"PAYIN_SUCCEEDED"},
                {label:"PAYIN_FAILED"},
                {label:"TRANSFER_DONE"},
                {label:"CANCELLED"}
            ],
            currentPage: 1,
            lastPage: null,
            filterStatuses: null,
            filterPublicId: null,
            filterTypes: null,
            types: ['swikly', 'mangopay']
        }
    },
    methods: {

        async loadOrderReservations(resetPage) {

            if(resetPage)
                this.currentPage = 1

            if (this.filterPublicId != null) 
                this.filterStatuses = null

            let response = await orderReservationsService.listOrderReservations(this.currentPage, this.filterStatuses, this.filterPublicId, this.filterTypes)
            this.orderReservations = response.data.data
            this.lastPage = response.data.meta.pagination.total_pages
        },
        expireDisplay(daysExp) {

            if (daysExp <= 0) {

                return 'Expired ' + '('+(-daysExp) +' jours)'
            }
            return daysExp
        },
        async extractAll() {

            await orderReservationsService.extractAll()
        }
    },
    created() {

        this.loadOrderReservations()
    },
}
</script>
<template>
    <dashboard-layout>
        <h2>Order Reservations</h2>
        <div class="row mt-3">
            <div class="col-2">
                <label for="selectType">Type</label>
                <select v-model="filterTypes" id="selectType" class="form-select ml-2">
                    <option :value="null">All</option>
                    <option v-for="orderReservationtype in types" :key="orderReservationtype" :value="orderReservationtype">{{orderReservationtype}}</option>
                </select>
            </div>
            <div class="col-2">
                <label for="selectStatus">Status</label>
                <select v-model="filterStatuses" id="selectStatus" class="form-select ml-2">
                    <option :value="null">All</option>
                    <option v-for="status in statuses" :key="status.label" :value="status.label">{{status.label}}</option>
                </select>
            </div>
            <div class="col-2">
                <input placeholder="Order Public Id" class="form-control" v-model="filterPublicId" />
            </div>
            <div class="col-2">
                <button type="submit" class="btn btn-primary" @click="loadOrderReservations(true)">Search</button>
            </div>
            <div class="col-2">
                <button type="submit" class="btn btn-success" @click="extractAll()">Extract all</button>
            </div>
            
        </div>
        <div class="d-flex my-3 align-items-center">
            <p class="m-0">Page : </p>
            <select v-model="currentPage" @change="loadOrderReservations(false)" style="height:25px">
                <option :value="n" v-bind:key="n" v-for="n in lastPage">
                    {{ n }}
                </option>
            </select>
            <p class="m-0"> / {{ lastPage }}</p>
        </div>
        <table class="table table-striped">
            <thead>
                <th></th>
                <th>Order Public Id</th>
                <th>Merchant</th>
                <th>Creation Date</th>
                <th>User name</th>
                <th>User email</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Debited amount</th>
                <th>Status</th>
                <th>Days Before Expiration</th>
                <th>Order status</th>
            </thead>
            <tbody>
                <tr v-for="orderReservation in orderReservations" :key="orderReservation.id">
                    <td><router-link :to="{name: 'orderReservationDetail', params: {'ori' : orderReservation.id}}"><i class="bi bi-eye"></i></router-link></td>
                    <td><router-link :to="{name: 'orderDetails', params: {orderId: orderReservation.reference}}">{{orderReservation.reference}}</router-link></td>
                    <td>{{orderReservation.merchant_name}}</td>
                    <td class="text-nowrap">{{orderReservation.created_at}}</td>
                    <td>{{orderReservation.user_name}}</td>
                    <td>{{orderReservation.user_email}}</td>
                    <td>{{orderReservation.type}}</td>
                    <td>{{orderReservation.amount / 100 }}€</td>
                    <td>{{orderReservation.debited_amount / 100 }} €</td>
                    <td>{{orderReservation.status}}</td>
                    <td class="text-success" :class="{'text-danger' : orderReservation.daysBeforeExp < 0}">{{expireDisplay(orderReservation.daysBeforeExp)}}</td>
                    <td>{{orderReservation.order_status}}</td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex my-3 align-items-center">
            <p class="m-0">Page : </p>
            <select v-model="currentPage" @change="loadOrderReservations(false)" style="height:25px">
                <option :value="n" v-bind:key="n" v-for="n in lastPage">
                    {{ n }}
                </option>
            </select>
        </div>
    </dashboard-layout>
</template>
