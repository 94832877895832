<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import kycService from '../services/kyc'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            kyc: null,
            error: null
        }
    },
    created() {

        this.loadKyc()
    },
    methods: {

        async loadKyc() {

            let response = await kycService.get(this.kycId)
            this.kyc = response.data
        },
        async getFile1() {

            let response = await kycService.getKycFile1(this.kycId)
            let file = response.data
            this.openFile(file.content, file.extension)
        },
        async getFile2() {

            let response = await kycService.getKycFile2(this.kycId)
            let file = response.data
            this.openFile(file.content, file.extension)
        },
        async openFile(content, extension) {

            let a = document.createElement("a")
            document.body.appendChild(a);
            a.style = "display: none"

            let byteCharacters = atob(content)
            let byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            let byteArray = new Uint8Array(byteNumbers)
            let type = ''
            switch (extension.toLowerCase()) {

                case 'pdf':
                    type = 'application/pdf'
                    break
                case 'jpeg':
                case 'jpg':
                    type = 'image/jpeg'
                    break
                case 'png':
                    type = 'image/png'
                    break
                case 'gif':
                    type = 'image/gif'
                    break
            }
            let file = new Blob([byteArray], { type: type+';base64' })
            let fileURL = URL.createObjectURL(file)
            
            a.href = fileURL
            a.setAttribute('target', '_blank')
            a.click();
        },
        async validate() {

            this.error = null

            try {

                await kycService.validate(this.kycId, this.kyc.first_name, this.kyc.last_name, this.kyc.identity_type, this.kyc.number, this.kyc.delivery, this.kyc.country, this.kyc.user.nationality, this.kyc.user.residence_country, this.kyc.user.birthday)
                this.loadKyc()
            }
            catch (error) {

                this.error = error
            }
        },
        async refuse() {

            this.error = null
            
            try {

                await kycService.refuse(this.kycId)
                this.loadKyc()
            }
            catch (error) {

                this.error = error
            }
        }
    },
    computed: {

        kycId() {

            return this.$route.params.kycId
        },
        canEdit() {

            return this.kyc && this.kyc.status === 'to_validate'
        },
        displayedStatus() {

            if (this.kyc == undefined) return ''
            if (this.kyc.status === 'to_validate') return this.kyc.status
            if (this.kyc.status === 'treated') return this.kyc.is_validated ? 'validated' : 'refused'

            return ''
        }
    },
}

</script>
<template>
    <dashboard-layout>
        <h2>Kyc Detail</h2>
        <h3>User</h3>
        <table v-if="kyc && kyc.user" class="table table-bordered">
            <tr>
                <th>First Name</th>
                <td>{{kyc.user.first_name}}</td>
            </tr>
            <tr>
                <th>Last Name</th>
                <td>{{kyc.user.last_name}}</td>
            </tr>
        </table>
        <h3>
            Kyc
            <button :disabled="!kyc || !kyc.file1" @click="getFile1" class="btn btn-info">Show file 1</button>
            <button :disabled="!kyc || !kyc.file2" @click="getFile2" class="btn btn-info ml-2">Show file 2</button>
        </h3>
        <table v-if="kyc" class="table table-bordered">
            <tr>
                <th>Status</th>
                <td>{{displayedStatus}}</td>
            </tr>
            <tr>
                <th>First Name</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.first_name"></td>
            </tr>
            <tr>
                <th>Last Name</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.last_name"></td>
            </tr>
            <tr>
                <th>Type</th>
                <td>
                    <select :disabled="!canEdit" v-model="kyc.identity_type">
                        <option></option>
                        <option value="identity_card">identity_card</option>
                        <option value="passport">passport</option>
                        <option value="resident_permit">resident_permit</option>
                        <option value="driver_licence">driver_licence</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th>Number</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.number"></td>
            </tr>
            <tr>
                <th>Delivery Date</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.delivery" placeholder="2020-11-27"></td>
            </tr>
            <tr>
                <th>Country</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.country" placeholder="FR"></td>
            </tr>
        </table>
        <table v-if="kyc && kyc.user.cash_orders_count > 0" class="table table-bordered">
            <tr>
                <th>Nationality</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.user.nationality" placeholder="FR"></td>
            </tr>
            <tr>
                <th>Residency Country</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.user.residence_country" placeholder="FR"></td>
            </tr>
            <tr>
                <th>Birthday</th>
                <td><input :disabled="!canEdit" type="text" v-model="kyc.user.birthday" placeholder="2020-11-27"></td>
            </tr>
        </table>
        <div v-if="error" class="alert alert-danger">{{error}}</div>
        <button :disabled="!canEdit" @click='refuse' class="btn btn-danger">Refuse</button>
        <button :disabled="!canEdit" @click="validate" class="btn btn-success ml-4">Validate</button>
    </dashboard-layout>
</template>
