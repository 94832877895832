import axios from 'axios'

export default {

    list(isAttached) {

        let path = '/api/bo/payins'
        if (isAttached !== undefined) {

            path = path + '?isAttached=' + isAttached
        }

        return axios.get(path);
    },
    get(payinId) {

        let path = '/api/bo/payins/' + payinId

        return axios.get(path);
    },
    attach(payinId, data) {

        let path = '/api/bo/payins/' +  payinId + '/attach'

        return axios.post(path, data);
    },
    changeToSubscription(payinId) {

        let path = '/api/bo/payins/' +  payinId + '/toSubscription'

        return axios.patch(path);
    }
}
