<script type="text/ecmascript-6">

import { uuid } from 'vue-uuid'
export default {

    props: {
        'files': Array,
        'maxNbrFiles': {
            type: Number,
            default:4
        },
        'btnLabel' : String,
        'totalFiles': Number
    },
    data() {

        return {

            previewImages: [],
            filesData: this.files,
            storageUsed: 0,
        }
    },
    methods: {

        addFile(e) {

            let droppedFiles = e.dataTransfer != undefined ? e.dataTransfer.files : e.target.files
            
            if (droppedFiles.length > this.maxNbrFiles) {
                
                alert('Vous ne pouvez pas importer plus de ' + this.maxNbrFiles + ' photo(s)');
                
                return;
            }

            var error
            var newDroppedFiles = []
            
            for (let i = 0; i < droppedFiles.length; i++) {
                const element = droppedFiles[i];

                if (element.size < 2050000) {

                    newDroppedFiles.push(element)
                    this.storageUsed += element.size
                }
                else {

                    error = 'Une ou plusieurs photos ont été retirées car elles dépassent les 2 Mo.'
                }
            }
            this.$emit('updateStorageData', this.storageUsed)
            droppedFiles = newDroppedFiles
            
            if (!droppedFiles) return
            ([...droppedFiles]).forEach(f => {
                
                let id = uuid.v4()
                this.filesData.push({id: id, file: f})
                this.addPreview(id, f)
                this.$emit('listingSteps')
            });

            if (error) {

                alert(error);
            }

            this.$emit("updateFiles", this.filesData);
        },

        removeFile(id) {

            this.filesData = this.filesData.filter(file => {
                
                this.storageUsed - file.size
                return file.id != id
            })
            this.previewImages = this.previewImages.filter(image => {

                return image.id != id
            })

            this.$emit('updateStorageData', this.storageUsed)
            this.$emit("updateFiles", this.filesData);
            this.$emit('listingSteps')
        },
        addPreview(id, file) {

            let reader = new FileReader
            reader.onload = e => {

                let preview = e.target.result

                this.previewImages.push({id: id, preview})
            }

            return reader.readAsDataURL(file)
        },
        zoomPhoto(preview) {

            this.$emit("listingPhotoClicked", preview);
        },
        clickOnInput() {

            this.$refs.fileInput.click();
        },
    },
    computed: {

        filesCharged() {

            return this.files.length >= 2
        },
    }
}
</script>
<template>
    <div>
        <input class="d-none" id="fileInput" ref="fileInput" :multiple="maxNbrFiles > 1" type="file" @change="addFile" accept="application/pdf,image/jpg,image/jpeg,image/png,image/gif">
        <div class="d-flex align-items-center">
            <button :disabled="files.length > 3" class="btn btn-outline-primary me-4" @click="clickOnInput">
                <span>{{ btnLabel }}</span>
            </button>
            <div class="d-flex flex-column ml-2">
                <span>{{this.files.length}} files / {{this.maxNbrFiles}} max</span>
            </div>
        </div>
        <div v-for="preview in previewImages" :key="preview.id" class="row p-2 d-flex flex-row">
            <object @click="zoomPhoto(preview.preview)" style="max-width:500px; max-height:500px" class="m-2 border col-12" :data="preview.preview"></object>
            <span @click="removeFile(preview.id)" class="bg-danger text-white ms-1 px-2 delete-btn border mt-2">X</span>
        </div>
    </div>
</template>
<style scoped>

.photo {

    height: 200px;
    width: 300px;
    border: 1px solid var(--bs-gray-400);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
}

.delete-btn {

    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 4px;
}

</style>