<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import couponService from '../services/coupon'
import payoutService from '../services/payout'
import StandardModal from '@/components/StandardModal.vue'

export default {
    
    components: {DashboardLayout, StandardModal},
    data() {

        return {
            coupons: [],
            withPayout: true,
            selectedCoupons: [],
            confirmPayout: false,
            error: null,
            merchantId: 5049681456
        }
    },
    created() {

        this.loadCoupons()
    },
    methods: {

        async loadCoupons() {

            let response = await couponService.list(this.withPayout, this.merchantId)
            this.coupons = response.data
        },
        getFrom(coupons) {

            if (coupons.length > 0 && coupons[0].merchant_id) {

                return coupons[0].merchant_id
            }

            return 'n/a'
        },
        async makePayout() {

            this.error = null
            try {

                let ids = this.selectedCoupons.map(coupon => coupon.id)
                await payoutService.createByCoupons(ids)
                this.confirmPayout = false;
            }
            catch (e) {

                this.error = e.message
            }

            if (this.error == null) {

                this.selectedCoupons = []
                this.confirmPayout = false
                await this.loadCoupons()
            }
        }
    },
    watch: {

        withPayout() {

            this.loadCoupons()
        },
        merchantId() {

            this.loadCoupons()
        }
    },
    computed: {

        displayPayout() {

            return this.withPayout === false
        },
        totalPayout() {

            if (this.selectedCoupons.length == 0) return 0

            return this.selectedCoupons.reduce((total, coupon) => total + coupon.amount, 0)
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Coupons</h2>
        <div class="custom-control custom-switch ml-2">
            <input type="checkbox" class="custom-control-input" v-model="withPayout" id="paidSwitch">
            <label v-if="!withPayout" class="custom-control-label" for="paidSwitch">Without payout</label>
            <label v-if="withPayout" class="custom-control-label" for="paidSwitch">With payout</label>
        </div>
        <div class="ml-2">
            <select v-model="merchantId" id="selectMerchantId">
                <option value="5049681456">5049681456</option>
                <option value="6211121839">6211121839</option>
                <option value="7564760076">7564760076</option>
            </select>
            <label>Merchant ID</label>
        </div>
        <button v-if="displayPayout" :disabled="selectedCoupons.length <= 0" type="button" @click="confirmPayout = true" class="btn btn-warning mt-2 mb-2">Payout {{totalPayout | moneyInteger}}</button>
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <th>ID</th>
                    <th>Public ID</th>
                    <th>Created at</th>
                    <th>Merchant</th>
                    <th>Amount</th>
                    <th>Payout</th>
                </thead>
                <tbody>
                    <tr v-for="coupon in coupons" :key="coupon.id">
                        <td>{{coupon.id}}</td>
                        <td>{{coupon.public_id}}</td>
                        <td>{{coupon.created_at | date}}</td>
                        <td>{{coupon.merchant_id}}</td>
                        <td>{{coupon.amount | moneyInteger}}</td>
                        <td>
                            <input v-if="coupon.payout == undefined" type="checkbox" :value="coupon" v-model="selectedCoupons" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Modal -->
        <standard-modal v-if="confirmPayout" :closeLabel="'Annuler'" :title="'Confirm payout'" @close="confirmPayout = false">
            <template v-slot:body>
                <div v-if="error" class="alert alert-danger" role="alert">
                    {{error}}
                </div>
                Are you sure to make a payout from <strong>{{getFrom(selectedCoupons)}}</strong>'s wallet to their bank account for <strong>{{totalPayout | moneyInteger}}</strong>?
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-danger" @click="makePayout">Make payout</button>
            </template>
        </standard-modal>
    </dashboard-layout>
</template>