<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import deliveryServices from '../services/delivery'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            deliveryNotes: [],
            loadingDelivery: null,
            isSyncLoading: false
        }
    },
    methods: {

        async loadDeliveryNotes() {

            let response = await deliveryServices.list()
            this.deliveryNotes = response.data
        },
        async forceStatus(publicId, status) {

            this.loadingDelivery = publicId
            await deliveryServices.forceStatus(publicId, status)
            this.loadingDelivery = null
        },
        isLoading(publicId) {

            return publicId == this.loadingDelivery
        },
        async syncDeliveries() {

            this.isSyncLoading = true
            await deliveryServices.sync()
            this.isSyncLoading = false
        }
    },
    created() {

        this.loadDeliveryNotes()
    },
    computed: {

        isEnvProduction() {

            return process.env.NODE_ENV == 'production' && process.env.VUE_APP_IS_STAGING != 'true'
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>
            Delivery notes
            <button v-if="!isEnvProduction" class="btn btn-warning ml-2" role="button" @click="syncDeliveries">
                <div v-if="isSyncLoading" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                </div>
                Sync
            </button>
        </h2>
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <th>ID</th>
                    <th>Tracking</th>
                    <th>Status</th>
                    <th>Action</th>
                </thead>
                <tbody>
                    <tr v-for="note in deliveryNotes" :key="note.public_id" class="">
                        <td class="align-middle">
                            <router-link v-if="note.order" class="align-middle" title="Click to open details" :to="{name: 'orderDetails', params: {orderId: note.order.public_id}}">
                                {{note.public_id}}
                            </router-link>
                        </td>
                        <td class="align-middle">{{note.tracking_number}}</td>
                        <td class="align-middle">{{note.status}}</td>
                        <td class="align-middle">
                            <button v-if="!isEnvProduction && !isLoading(note.public_id) && note.status == 'created'" class="btn btn-warning" @click="forceStatus(note.public_id, 'sent')">Set as SENT</button>
                            <button v-if="!isEnvProduction && !isLoading(note.public_id) && note.status == 'sent'" class="btn btn-warning" @click="forceStatus(note.public_id, 'delivered')">Set as delivered</button>
                            <button v-if="!isEnvProduction && isLoading(note.public_id)" class="btn btn-warning">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>
