<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import kycService from '../services/kyc'

export default {
    
    components: {DashboardLayout},
    data() {

        return {

            kyc: [],
            kycToDisplay: [],
            filter: ''
        }
    },
    created() {

        this.loadKyc()
    },
    methods: {

        async loadKyc() {

            let response = await kycService.list()
            this.kyc = response.data
            this.filter = 'to_validate'
        },
        async downloadKycFiles(kycId) {

            let response = await kycService.getKycFiles(kycId)
            this.openZIP(response.data, 'greendid-kyc-'+kycId+'.zip')
        },
        openZIP(content, filename) {

            const url = window.URL.createObjectURL(new Blob([content]))
            const link = document.createElement('a')
            link.href = url

            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
        },
    },
    computed: {

        kycToValidate() {

            return this.kyc.filter(element => element.status == 'to_validate')
        },
        kycNotValidated() {

            return this.kyc.filter(element => element.status == 'treated' && element.is_validated == false)
        },
        kycValidated() {

            return this.kyc.filter(element => element.status == 'treated' && element.is_validated == true)
        },
    },
    watch: {

        filter(newFilter) {

            switch (newFilter) {

                case 'to_validate':
                    this.kycToDisplay = this.kycToValidate
                    break;
                case 'not_validated':
                    this.kycToDisplay = this.kycNotValidated
                    break;
                case 'validated':
                    this.kycToDisplay = this.kycValidated
                    break;
            }
        }
    }
}

</script>
<template>
    <dashboard-layout>
        <h2>Kyc</h2>
        <div class="form-group col-md-4">
            <select class="form-control" v-model="filter">
                <option value="to_validate">To validate</option>
                <option value="not_validated">Not validated</option>
                <option value="validated">Validated</option>
            </select>
        </div>
        <table class="table table-striped">
            <thead>
                <th>Created</th>
                <th>User ID</th>
                <th>Identity type</th>
                <th>File1</th>
                <th>File2</th>
                <th>First name</th>
                <th>Last name</th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="kyc in kycToDisplay" :key="kyc.id">
                    <td>{{kyc.created_at | date}}</td>
                    <td>{{kyc.user_id}}</td>
                    <td>{{kyc.identity_type}}</td>
                    <td>{{kyc.file1}}</td>
                    <td>{{kyc.file2}}</td>
                    <td>{{kyc.first_name}}</td>
                    <td>{{kyc.last_name}}</td>
                    <td><router-link :to="{name: 'kycDetail', params: {kycId: kyc.id}}"><i class="bi bi-eye"></i></router-link></td>
                </tr>
            </tbody>
        </table>
    </dashboard-layout>
</template>
