<script type="text/ecmascript-6">

import DashboardLayout from '../layouts/Dashboard.vue'
import merchantServices from '../services/merchant.js'

export default {
    
    components: {DashboardLayout},
    data() {

        return {
            merchants: []
        }
    },
    methods: {

        async loadMerchants() {

            let response = await merchantServices.list()
            this.merchants = response.data
        },
    },
    created() {

        this.loadMerchants()
    }
}
</script>
<template>
    <dashboard-layout>
        <h2>Merchants</h2>
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <th>ID</th>
                    <th>Name</th>
                </thead>
                <tbody>
                    <tr v-for="merchant in merchants" :key="merchant.id">
                        <td class="align-middle">{{ merchant.id }}</td>
                        <td class="align-middle">
                            <router-link :to="{name: 'merchantDetails', params: {merchantId: merchant.id}}">{{ merchant.name }}</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </dashboard-layout>
</template>
