import Vue from 'vue'
import VueRouter from 'vue-router'
import authGuard from './guards/auth.js'
import StripeAccountCreate from '../views/stripe/CreateAccount.vue'
import DeliveryNotes from '../views/DeliveryNotes.vue'
import Payins from '../views/Payins.vue'
import Payouts from '../views/Payouts.vue'
import Orders from '../views/Orders.vue'
import Invoices from '../views/Invoices.vue'
import Kyc from '../views/Kyc.vue'
import KycDetail from '@/views/KycDetail.vue'
import Payin from '../views/Payin.vue'
import Login from '../views/Login.vue'
import Transfers from '../views/Transfers.vue'
import AnalyticsBaskets from '../views/Analytics/Baskets.vue'
import VueMeta from 'vue-meta'
import FailedJobs from '../views/FailedJobs.vue'
import Jobs from '../views/Jobs.vue'
import JobExecution from '../views/JobExecution.vue'
import Settings from '../views/Settings.vue'
import InvoicesBatch from '../views/InvoicesBatch.vue'
import OrderDetails from '../views/OrderDetails.vue'
import AnalyticsResellerProductOffer from '../views/Analytics/ResellerProductOffer.vue'
import BrandCampaigns from '@/views/BrandCampaigns.vue'
import BrandCampaign from '@/views/BrandCampaign.vue'
import AnalyticsBusiness from '../views/Analytics/Business.vue'
import AnalyticsBtb from '@/views/Analytics/Btb.vue'
import Coupons from '@/views/Coupons.vue'
import OrderPrices from '@/views/OrderPrices.vue'
import OrderReservations from '@/views/OrderReservations.vue'
import OrderReservationDetail from '@/views/OrderReservationDetail.vue'
import OrderReservationReclaims from '@/views/OrderReservationReclaims.vue'
import OrderReservationReclaimDetail from '@/views/OrderReservationReclaimDetail.vue'
import Resellers from '@/views/Resellers.vue'
import ResellerDetails from '@/views/ResellerDetails.vue'
import ResellerInvoices from '@/views/ResellerInvoices.vue'
import LostPackages from '@/views/LostPackages.vue'
import Listings from '@/views/Listings.vue'
import ListingDetails from '@/views/ListingDetails.vue'
import Merchants from '@/views/Merchants.vue'
import MerchantDetails from '@/views/MerchantDetails.vue'
import StripePayin from '@/views/StripePayin.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {name: 'deliveryNotes'},
        beforeEnter: authGuard.ifNotAuthenticated
    },
    {
        path: '/stripe/accounts/create',
        name: 'stripeAccountCreate',
        component: StripeAccountCreate,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: authGuard.ifNotAuthenticated
    },
    {
        path: '/deliveryNotes',
        component: DeliveryNotes,
        name: 'deliveryNotes',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/payins',
        component: Payins,
        name: 'payins',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/payouts',
        component: Payouts,
        name: 'payouts',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/payins/:id',
        component: Payin,
        name: 'payin',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/invoices',
        component: Invoices,
        name: 'invoices',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/orders',
        component: Orders,
        name: 'orders',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/transfers',
        component: Transfers,
        name: 'transfers',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/analytics/baskets',
        component: AnalyticsBaskets,
        name: 'analyticsBaskets',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/kyc',
        component: Kyc,
        name: 'kyc',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/kyc/:kycId',
        component: KycDetail,
        name: 'kycDetail',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/failedJobs',
        component: FailedJobs,
        name: 'failedJobs',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/jobs',
        component: Jobs,
        name: 'jobs',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/jobExecution',
        component: JobExecution,
        name: 'jobExecution',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/settings',
        component: Settings,
        name: 'settings',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/invoicesBatch/:batchId',
        name: 'invoicesBatch',
        component: InvoicesBatch,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/orders/:orderId',
        name: 'orderDetails',
        component: OrderDetails,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/orders/:orderId/prices',
        name: 'orderPrices',
        component: OrderPrices,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/analytics/ResellerProductOffer',
        component: AnalyticsResellerProductOffer,
        name: 'analyticsResellerProductOffer',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/brandCampaigns',
        component: BrandCampaigns,
        name: 'brandCampaigns',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/brandCampaigns/:brandCampaignId',
        component: BrandCampaign,
        name: 'brandCampaign',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/analytics/Business',
        component: AnalyticsBusiness,
        name: 'analyticsBusiness',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/analytics/Btb',
        component: AnalyticsBtb,
        name: 'analyticsBtb',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/coupons',
        component: Coupons,
        name: 'coupons',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/orderReservationReclaims',
        component: OrderReservationReclaims,
        name: 'orderReservationReclaims',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/orderReservations',
        component: OrderReservations,
        name: 'orderReservations',
        beforeEnter: authGuard.ifAuthenticated
    }
    ,
    {
        path: '/orderReservationReclaims/:jobId',
        component: OrderReservationReclaimDetail,
        name: 'orderReservationReclaimDetail',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/orderReservationDetail/:ori',
        component: OrderReservationDetail,
        name: 'orderReservationDetail',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/resellers',
        component: Resellers,
        name: 'resellers',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/resellers/:resellerId',
        component: ResellerDetails,
        name: 'resellerDetails',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/resellers/:resellerId/invoices',
        component: ResellerInvoices,
        name: 'resellerInvoices',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/lostPackages',
        component: LostPackages,
        name: 'lostPackages',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/listings',
        component: Listings,
        name: 'listings',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/listings/:publicId',
        component: ListingDetails,
        name: 'listingDetails',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/merchants',
        component: Merchants,
        name: 'merchants',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/merchants/:merchantId',
        component: MerchantDetails,
        name: 'merchantDetails',
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/stripePayin',
        component: StripePayin,
        name: 'stripePayin',
        beforeEnter: authGuard.ifAuthenticated
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
