<script type="text/ecmascript-6">

import DashboardLayout from '@/layouts/Dashboard.vue'
import listingServices from '@/services/listing'
import mediaMixin from '@/mixins/media.js'
import StandardModal from '@/components/StandardModal.vue'
import ImportFiles from '@/components/ImportFiles.vue'

export default {
    components: {DashboardLayout, StandardModal, ImportFiles},
    mixins: [mediaMixin],
    data() {
        return {
            listing: null,
            displaySetPrice: false,
            newListing: {
                merchant_order_date: null,
                merchant_product_price: 0,
                price_greendid: 0,
                price_contribution: 0,
                price_total: 0
            },
            listingPhotosUpdated: [],
            photosLoading: false,
            listingPhotoClicked: null,
            sizePreview: null,
            openReassignModal: false,
            selectedResellerToReassign: null,
            modifyDetailsText: false,
            newDetailsText:'',
            togglePhotoModale: false,
            storageUsed: null,
            newPhotosToSubmit:[],
            modifyRecommendedPriceToggle: false,
            recommendedPrice: 0,
            isLoading: false, 
            error: null
        }
    },
    created() {

        this.loadListing()
    },
    methods: {

        async loadListing() {

            this.closeReassignModal()
            this.error = null
            this.newDetailsText = ''
            this.modifyDetailsText = false
            this.newPhotosToSubmit = []
            this.recommendedPrice = 0
            this.modifyRecommendedPriceToggle = false

            let listingPublicId = this.$route.params.publicId
            let response = await listingServices.get(listingPublicId)
            this.listing = response.data
            this.recommendedPrice = this.listing.recommended_price != null ? this.listing.recommended_price / 100 : 0

            this.loadPhotos()
        },
        async loadPhotos() {

            this.error = null
            this.photosLoading = true
            let response = await listingServices.getPhotos(this.listing.public_id)
            this.listingPhotos = response.data
            this.formatFiles(this.listingPhotos)
            
            this.photosLoading = false
        },
        formatFiles(files) {

            files.forEach(element => {
                    
                    let fileDetails = this.getTypeFile(element.charAt(0))
    
                    let preview = "data:" + fileDetails.dataType + ";base64," + element
                    let size =  fileDetails.sizePreview
                    this.listingPhotosUpdated.push({size,preview})
                });
        },
        getTypeFile(firstLetter) {

            var fileDetails = {}

            switch (firstLetter)
            {
                case 'J' : 

                    fileDetails = {

                        dataType: 'application/pdf',
                        extension:'pdf',
                        sizePreview: 'width:500px; height:500px'
                    }
                    
                    break;

                case 'i' : 

                    fileDetails = {

                        dataType: 'image/png',
                        extension:'png',
                        sizePreview: 'max-width:500px; max-height:500px'
                    }

                    break;

                case '/' : 

                    fileDetails = {

                        dataType: 'image/jpeg',
                        extension:'jpeg',
                        sizePreview: 'max-width:500px; max-height:500px'
                    }
                    break;

                case 'U' : 

                    fileDetails = {

                        dataType: 'image/jpeg',
                        extension:'webp',
                        sizePreview: 'max-width:500px; max-height:500px'
                    }
                    break;
                
            }

            return fileDetails
        },
        async downloadMerchantInvoice() {

            this.error = null
            let response = await listingServices.getMerchantInvoice(this.listing.public_id)
            let extension = this.listing.invoice_filename.split('.').pop()
            this.openFile(response.data, 'greendid-merchant-invoice-' + this.listing.public_id + '.' + extension)
        },
        async calculatePrice() {

            this.error = null
            let response = await listingServices.calculatePrice(this.listing.public_id, this.newListing.merchant_order_date, this.newListing.merchant_product_price * 100)
            this.newListing.price_greendid = response.data.price_greendid
            this.newListing.price_contribution = response.data.price_contribution
            this.newListing.price_total = response.data.price_total
        },
        async accept() {

            this.error = null
            await listingServices.accept(this.listing.public_id, this.newListing.merchant_order_date, this.newListing.merchant_product_price * 100)
            this.displaySetPrice = false
            this.$router.go(this.$router.currentRoute)
        },
        async refuse() {

            this.error = null
            await listingServices.refuse(this.listing.public_id, '')
            this.displaySetPrice = false
            this.$router.go(this.$router.currentRoute)
        },
        async reopen() {

            this.error = null
            try {

                let response = await listingServices.reopen(this.listing.public_id)
                if (response.data) {

                    this.loadListing()
                }
            }
            catch (error) {

                this.error = 'Unable to re-open the listing'
            }
        },
        async reassign() {

            this.error = null
            let response = await listingServices.reassign(this.listing.public_id, this.selectedResellerToReassign.id)
            if (response.data) {

                this.loadListing()
            }
        },
        async submitNewDetailText() {

            this.error = null
            let response = await listingServices.updateDetailText(this.listing.public_id, this.newDetailsText)
            if (response.data) {

                this.loadListing()
            }
        },
        async updateRecommendedPrice() {

            this.isLoading = true
            this.error = null
            let response = await listingServices.updateRecommendedPrice(this.listing.public_id, this.recommendedPrice)
            if (response.data) {

                this.loadListing()
            }
            this.isLoading = false
        },
        async submitNewPhotos() {

            this.error = null
            let response = await listingServices.updateFiles(this.listing.public_id, this.newPhotosToSubmit)
            if (response.data) {

                this.loadListing()
            }
            location.reload();
        },
        zoomPhoto(preview) {

            let fileDetails = this.getTypeFile(preview.charAt(0))

            this.listingPhotoClicked = preview
            this.sizePreview = fileDetails.sizePreview
        },
        closeReassignModal() {

            this.selectedResellerToReassign = null
            this.openReassignModal = false

        },
        modifyDetailsTextFunc() {

            this.newDetailsText = ''
            this.modifyDetailsText = !this.modifyDetailsText

            if (this.modifyDetailsText) {

                this.newDetailsText = this.listing.details
            }
        },
        openPhotoModale() {

            this.togglePhotoModale = !this.togglePhotoModale
        },
        updateStorageData(storage) {

            this.storageUsed = storage
        },
    },
    computed: {

        isListingLoaded() {

            return this.listing != undefined
        },
        isFilesSizeOk() {

            return this.storageUsed < 8350000 ? false : true
        },
    }
}
</script>
<template>
    <dashboard-layout>
        <div v-if="isListingLoaded">
            <h2>{{listing.public_id}}</h2>
            <button :disabled="listing.status === 'to_validate'" class="btn btn-success m-2" @click="reopen">Reopen</button>
            <button :disabled="listing.status === 'accepted'" class="btn btn-info m-2" @click="openReassignModal = !openReassignModal">Reassign</button>
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <h4 class="mt-2">Listing</h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>Merchant</th>
                        <td class="align-middle"><img width="50px" :src="getMerchantAsset(listing.merchant.id, listing.merchant.asset_logo_name)" /></td>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <td class="align-middle">{{listing.created_at | date}}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td class="align-middle">{{listing.status}}</td>
                    </tr>
                    <tr v-if="listing.refused_reason">
                        <th>Refused reason</th>
                        <td class="align-middle">{{listing.refused_reason}}</td>
                    </tr>
                    <tr>
                        <th>Reseller</th>
                        <td class="align-middle">{{listing.reseller.name}}</td>
                    </tr>
                    <tr>
                        <th>Price</th>
                        <td class="align-middle text-right">{{listing.price | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Contribution amount</th>
                        <td class="align-middle text-right">{{listing.contribution_amount | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Total Price</th>
                        <td class="align-middle text-right">{{listing.total_price | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Com (excl tax)</th>
                        <td class="align-middle text-right">{{listing.commission_excl_tax_price | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Log (excl tax)</th>
                        <td class="align-middle text-right">{{listing.logistic_excl_tax_price | moneyInteger}}</td>
                    </tr>
                    <tr>
                        <th>Recommended Price</th>
                        <td class="align-middle text-right">
                            <span>
                                <input class="mb-3 text-right" type="number" v-model="recommendedPrice">
                                <button :disabled="isLoading" class="btn btn-success col-12" @click="updateRecommendedPrice">Validate new amount <div v-if="isLoading" class="spinner-border spinner-border-sm" role="status"></div></button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4 class="mt-5">Product</h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>Brand</th>
                        <td class="align-middle">{{listing.brand.name}}</td>
                    </tr>
                    <tr>
                        <th>Category</th>
                        <td class="align-middle">{{listing.category.name}}</td>
                    </tr>
                    <tr>
                        <th>Model</th>
                        <td class="align-middle">{{listing.model}}</td>
                    </tr>
                    <tr>
                        <th>Year</th>
                        <td class="align-middle">{{listing.year}}</td>
                    </tr>
                    <tr>
                        <th>Conditions</th>
                        <td class="align-middle">{{listing.product_condition.name}}</td>
                    </tr>
                    <tr>
                        <th>
                            <div class="d-flex flex-column">
                            <span>Details</span> 
                            <button class="mt-3 btn btn-info" @click="modifyDetailsTextFunc">Modify</button>
                        </div>
                    </th>
                        <td class="d-flex flex-column" v-if="modifyDetailsText"> 
                            <textarea class="align-middle w-100 mb-3" v-model="newDetailsText" style="height:200px"></textarea>
                            <button :disabled="listing.details === newDetailsText" class="btn btn-success" @click="submitNewDetailText">Submit modifications</button>
                        </td>
                       
                        <td v-else class="align-middle">{{listing.details}}</td>
                    </tr>
                    <tr>
                        <th>Invoice</th>
                        <td class="align-middle">
                            <button v-if="listing.invoice_filename" @click="downloadMerchantInvoice" class="btn btn-info">Download</button>
                        </td>
                    </tr>
                    <tr v-if="listing.status == 'to_validate_greendid'">
                        <th>Set Price</th>
                        <td class="align-middle">
                            <button @click="displaySetPrice = true" class="btn btn-info">...</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4 v-if="listing.contract != undefined" class="mt-5">Contract</h4>
            <table v-if="listing.contract != undefined" class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>Public ID</th>
                        <td class="align-middle">{{ listing.contract.public_id }}</td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td class="align-middle">{{ listing.contract.type }}</td>
                    </tr>
                    <tr>
                        <th>Start At</th>
                        <td class="align-middle">{{ listing.contract.start_at | date }}</td>
                    </tr>
                    <tr>
                        <th>Can do resale</th>
                        <td class="align-middle">{{ listing.contract.can_do_resale }}</td>
                    </tr>
                </tbody>
            </table>
            <h4 class="mt-5">User</h4>
            <table class="table table-bordered col-md-6">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td class="align-middle">{{listing.first_name}} {{listing.last_name}}</td>
                    </tr>
                </tbody>
            </table>
            <h4 class="mt-5">Photos</h4>
            <import-files  @listingPhotoClicked="listingPhotoClicked = $event" :files="newPhotosToSubmit" @updateFiles="newPhotosToSubmit = $event"  @updateStorageData="updateStorageData" :btnLabel="'Add photos'"></import-files>
            <button :disabled="newPhotosToSubmit.length < 1" class="btn btn-primary mt-2" @click="submitNewPhotos">Submit new photos</button>
            <table class="table table-bordered font-size-sm bg-white mt-4">
                <tbody>
                    <tr>
                        <td class="align-middle">
                            <div class="row p-2 d-flex">
                                <span v-if="photosLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                <div :key="index" v-for="(preview, index) in listingPhotosUpdated" class="row p-2 d-flex flex-row">
                                    <div @click="zoomPhoto(preview.preview)">
                                        <object :style="preview.size" class="m-2 border col-12" :data="preview.preview"></object>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <standard-modal v-if="displaySetPrice" :closeLabel="'Close'" :title="'Set Price'" @close="displaySetPrice = false">
            <template v-slot:body>
                <div class="modal-body">
                    <table>
                        <tr>
                            <th>Order Date</th>
                            <td><input type="text" v-model="newListing.merchant_order_date" placeholder="2023-05-15" /></td>
                        </tr>
                        <tr>
                            <th>Product Price</th>
                            <td><input type="text" v-model="newListing.merchant_product_price" /></td>
                        </tr>
                    </table>
                    <button class="btn btn-info mt-2 mb-2" @click="calculatePrice">Calculate</button>
                    <table>
                        <tr>
                            <th>Greendid Price</th>
                            <td><input type="text" disabled v-model="newListing.price_greendid" /></td>
                        </tr>
                        <tr>
                            <th>Greendid Price % </th>
                            <td><input type="text" disabled :value="Math.round(newListing.price_greendid / newListing.merchant_product_price * 100)" /></td>
                        </tr>
                        <tr>
                            <th>Contribution amount</th>
                            <td><input type="text" disabled v-model="newListing.price_contribution" /></td>
                        </tr>
                        <tr>
                            <th>Contribution amount %</th>
                            <td><input type="text" disabled :value="Math.round(newListing.price_contribution / newListing.merchant_product_price * 100)" /></td>
                        </tr>
                        <tr>
                            <th>Total Price</th>
                            <td><input type="text" disabled v-model="newListing.price_total" /></td>
                        </tr>
                        <tr>
                            <th>Total %</th>
                            <td><input type="text" disabled :value="Math.round(newListing.price_total / newListing.merchant_product_price * 100)" /></td>
                        </tr>
                    </table>
                    <button class="btn btn-success mt-2 mr-5" @click="accept">Validate</button>
                    <button class="btn btn-danger mt-2" @click="refuse">Refuse</button>
                </div>
            </template>
        </standard-modal>
        <standard-modal v-if="listingPhotoClicked != null" :closeLabel="'Retour'" @close="listingPhotoClicked = null">
            <template v-slot:body>
                <div style="width:100vh;height: 100vh">
                   <div class="row p-2 d-flex">
                        <object style="background-image: url(listingPhotoClicked.preview);" class="col-12" :data="listingPhotoClicked"></object>
                    </div>
                </div>
            </template>
        </standard-modal>
        <standard-modal v-if="openReassignModal" :closeLabel="'Retour'" @close="closeReassignModal">
            <template v-slot:body>
                <div style="width:100vh">
                    <p>Réattribuer cette annonce à : </p>
                    <div class="container-fluid">
                        <div class="d-flex">
                            <select v-model="selectedResellerToReassign" class="w-50 me-5">
                                <option v-for="reseller in listing.merchant.resellers" :key="reseller.id" :value="reseller">{{ reseller.name }}</option>
                            </select>
                            <button :disabled="selectedResellerToReassign == null" class="btn btn-success ml-2" @click="reassign">Validate</button>
                        </div>
                    </div>
                </div>
            </template>
        </standard-modal>
    </dashboard-layout>
</template>
